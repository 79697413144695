import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Divider, Table, TableCell, TableRow, Typography, useTheme, Link } from '@mui/material'
import styled from 'styled-components'
import {
  AssetFragment,
  BlogsContentAssetsFragment,
  TagsContentAssetsFragment,
  SectionsMinimalsVideoFeatureDescriptionAssetsFragment,
} from '@/generated/graphql'
import Image from '@/components/Image'
import palette from '@/theme/palette'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import ReactPlayer from 'react-player'

const ReactViewer = dynamic(() => import('react-viewer'), { ssr: false })

export const RichTextStyleWrapper = styled.div`
  p {
    font-size: inherit;
    white-space: pre-line;
  }

  // List
  & ul,
  & ol {
    padding-left: 1.25rem;
    & li {
      line-height: 2px;
    }
  }
`

const StyledImage = styled(Image)`
  margin: 0px 0px 0.75em;
  cursor: pointer;
`

const StyledBlockquote = styled.blockquote`
  line-height: 1.5;
  font-size: 1.5em;
  margin: 40px auto;
  position: relative;
  font-family: Georgia, serif;
  padding: 3rem 3.5rem 3rem 4.5rem;
  border-radius: 16px;
  background-color: ${palette.light.background.neutral};
  color: ${palette.light.text.primary} !important;
  width: 100%;
  display: block;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 !important;
  }

  &:before {
    left: 30px;
    top: 10px;
    display: block;
    position: absolute;
    font-size: 3em;
    content: '\\201C';
    position: absolute;
    color: ${palette.light.text.disabled};
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`

const StyledTable = styled(Table)`
  overflow: scroll;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${palette.light.grey[500]};
  margin: 15px 0;
`

const StyledTableCell = styled(TableCell)`
  border: 1px solid ${palette.light.grey[500]};
`
const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: ${palette.light.grey[200]};
    > td {
      background-color: ${palette.light.grey[200]};
    }
  }
`

const TableHeadCell = styled(StyledTableCell)`
  min-width: 150px;
  background-color: ${palette.light.grey[400]};
`

interface ReactViewerComponentProps {
  asset: AssetFragment
}

const isVideoFile = (url: string) => {
  const testUrl = url?.toLowerCase()
  if (testUrl?.includes('mp4')) return true
  if (testUrl?.includes('mov')) return true
  return false
}

const ReactViewerComponent = (props: ReactViewerComponentProps) => {
  const theme = useTheme()
  const { asset } = props
  const [displayImageSrc, setDisplayImageSrc] = useState<string>('')
  const isVideo = isVideoFile(asset.url ?? '')
  if (isVideo) {
    return (
      <ReactPlayer
        loop
        width={'100%'}
        height={'50vh'}
        url={asset.url ?? undefined}
        controls={true}
      />
    )
  }
  return (
    <>
      <StyledImage
        onClick={() => setDisplayImageSrc(asset.url ?? '')}
        src={asset.url ?? ''}
        alt={asset.description ?? ''}
        ratio="16/9"
        sx={{ borderRadius: 1, my: 5 }}
      />
      <ReactViewer
        zIndex={theme.zIndex.modal}
        showTotal={false}
        changeable={false}
        noNavbar
        visible={!!displayImageSrc}
        onClose={() => setDisplayImageSrc('')}
        onMaskClick={() => setDisplayImageSrc('')}
        images={[{ src: asset.url ?? '' }]}
      />
    </>
  )
}

const getYoutubeVideoID = (url: string): string => {
  const match = url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/)
  if (match !== null && match[1].length === 11) {
    return match[1]
  } else {
    return ''
  }
}

export const renderOptionFunc = (
  asset?:
    | BlogsContentAssetsFragment
    | TagsContentAssetsFragment
    | SectionsMinimalsVideoFeatureDescriptionAssetsFragment
): Options => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const id = node.data.target.sys.id
      const matchedAsset = asset?.block?.find((item) => item?.sys.id === id)
      if (matchedAsset) {
        return <ReactViewerComponent asset={matchedAsset} />
      }
      return null
    },
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h1">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h2">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h4">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h5">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 0.75em' }} variant="h6">
        {children}
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Typography style={{ margin: '0px 0px 1.5em' }} variant="body1">
        {children}
      </Typography>
    ),
    [BLOCKS.TABLE]: (_node, children) => <StyledTable>{children}</StyledTable>,
    [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => <TableHeadCell>{children}</TableHeadCell>,
    [BLOCKS.TABLE_CELL]: (_node, children) => <StyledTableCell>{children}</StyledTableCell>,
    [BLOCKS.TABLE_ROW]: (_node, children) => <StyledTableRow>{children}</StyledTableRow>,
    [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (_node, children) => <StyledBlockquote>{children}</StyledBlockquote>,
    [BLOCKS.HR]: () => <Divider sx={{ my: 3 }} />,
    [INLINES.HYPERLINK]: (node) => {
      if (
        node?.data?.uri.toLowerCase().indexOf('.png') !== -1 ||
        node?.data?.uri.toLowerCase().indexOf('.jpg') !== -1 ||
        node?.data?.uri.toLowerCase().indexOf('.jpeg') !== -1 ||
        node?.data?.uri.toLowerCase().indexOf('.gif') !== -1
      ) {
        return <StyledImage src={node?.data?.uri} />
      }

      if (
        node?.data?.uri.indexOf('youtube.com/embed') !== -1 ||
        node?.data?.uri.indexOf('youtube.com/watch') !== -1
      ) {
        const videoId = getYoutubeVideoID(node?.data?.uri)
        const url =
          node.data.uri.indexOf('youtube.com/embed') !== -1
            ? node.data.uri
            : `https://www.youtube.com/embed/${videoId}`
        return (
          <div
            style={{
              position: 'relative',
              paddingTop: '56.25%',
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={url}
              title={url}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )
      }

      const domains = ['youtube.com/embed', 'app.tango.us/app/embed']
      const isMatch = domains.some((domain) => node?.data?.uri?.includes(domain))
      if (isMatch) {
        return (
          <div
            style={{
              position: 'relative',
              paddingTop: '56.25%',
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={node?.data?.uri}
              title={node?.data?.uri}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )
      }
      if (node?.data?.uri.indexOf('www.youtube.com/watch') !== -1) {
        return (
          <div
            style={{
              position: 'relative',
              paddingTop: '56.25%',
            }}
          >
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              url={node?.data?.uri ?? undefined}
              controls={true}
            />
          </div>
        )
      }
      if (node?.data?.uri.includes('https')) {
        const [{ value = 'Link' }] = node.content as any
        const src = node.data.uri ?? ''
        return (
          <Link style={{ textDecoration: 'underline' }} target="_blank" rel="noopener" href={src}>
            {value}
          </Link>
        )
      }
    },
  },
})
