import { m } from 'framer-motion'
import { styled } from '@mui/material/styles'
import {
  Button,
  Box,
  Container,
  Typography,
  Stack,
  StackProps,
  useTheme,
  useMediaQuery,
  TextField,
  Chip,
  Link,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import Iconify from '../../components/Iconify'
import { MotionContainer, varFade } from '../../components/animate'
import Typewriter from '@/components/Typewriter'
import Markdown from '@/components/Markdown'
import { Link as ScrollLink } from 'react-scroll'
import {
  PromptSupportingLanguageMap,
  SectionsHeroFragmentFragment,
  useVisitorGeneratePromptWithTagsLazyQuery,
  TagsForAllProductsFragment,
  useVisitorGeneratePromptLazyQuery,
} from '@/generated/graphql'
import { UseCase, formatPromptsPageUrl, systemLocaleTransformer } from '@/services/prompts/utils'
import { PATH_PAGE } from '@/routes/paths'
import NextLink from 'next/link'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { LoadingButton } from '@mui/lab'
import { notEmpty } from '@/utils/common'
import { $enum } from 'ts-enum-util'
import { SHARED_PROMPT_HEADER_FOR_HERO_SECTION } from '@/utils/constants'
import sampleSize from 'lodash/sampleSize'

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}))

const ContentStyle = styled((props: StackProps) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 600,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
)

const HeroOverlayStyle = styled(m.img)({
  zIndex: -1,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'fixed',
})

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '12%',
    width: 'auto',
    height: '33%',
    zIndex: 10,
  },
}))

const BackgroundVideo = styled(m.video)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  position: 'absolute',
  objectFit: 'cover',
}))
const BackgroundSource = styled(m.source)(({ theme }) => ({}))
const InputContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 10px;
`
const RecommendationInputContainer = styled(m.div)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  gridGap: '10px',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '10px',
  },
}))

const defaultBackgroundOverlay = '/assets/images/overlay.svg'
const defaultDisplayInput = `I am the smartest AI you'll ever meet! I'm here to assist you with anything you need, from answering your questions to helping you complete tasks.
Whether you're looking for a quick answer to a question or need some guidance with a project, I'm always here to lend a helping hand. And the best part? You don't have to worry about any awkward small talk or judgement, because I'm here to listen and provide you with the best possible response.
So go ahead, ask me anything!
  `

export default function LandingHero(props: {
  contentfulDataFragment: SectionsHeroFragmentFragment
  contentfulDataForProductSearchTagsFragment?: TagsForAllProductsFragment[]
}) {
  const {
    companyName,
    featureText,
    descriptionText,
    callToActionText,
    callToActionHref,
    backgroundImage,
    fullWidthBackgroundImageDesktop,
    fullWidthBackgroundImageMobile,
    enableAiTrail,
    enableAiTrailDisplayText,
    enableAiRecommendation,
    enableAiRecommendationDisplayText,
    sys,
  } = props.contentfulDataFragment
  const tags = props.contentfulDataForProductSearchTagsFragment ?? []
  const router = useRouter()
  const { enqueueSnackbar } = useSnackbar()
  const [inputText, setInputText] = useState('')
  const [inputTextDesc, setInputTextDesc] = useState<string | null>(null)
  const [defaultInput, setDefaultInput] = useState<string | null>(
    enableAiTrail
      ? enableAiTrailDisplayText ?? defaultDisplayInput
      : enableAiRecommendation
      ? enableAiRecommendationDisplayText ?? defaultDisplayInput
      : null
  )
  const [tagsInputText, setTagsInputText] = useState('')
  const [tagsOutputText, setTagsOutputText] = useState<string | null>(``)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const [visitorGeneratePromptWithTags, { loading: visitorGeneratePromptWithTagsQueryLoading }] =
    useVisitorGeneratePromptWithTagsLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setTagsOutputText(data.generatePromptWithTags.createResponse?.choices[0].text ?? '')
      },
      onError: () => {
        enqueueSnackbar('Failed to Chat, Try Later!', { variant: 'error' })
        setTagsOutputText(null)
      },
    })

  const [visitorGeneratePromptLazyQueryLazy, { loading: visitorGeneratePromptLazyQueryLoading }] =
    useVisitorGeneratePromptLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setInputTextDesc(data.generatePrompt.createResponse?.choices?.[0]?.text ?? '')
      },
      onError: () => {
        enqueueSnackbar('Failed to Chat, Try Later!', { variant: 'error' })
        setInputTextDesc(null)
      },
    })

  const allProductSearchTags =
    tags.map((item) => ({
      id: item?.sys.id ?? '',
      name: item?.name ?? '',
    })) ?? []

  const src = isMobile
    ? fullWidthBackgroundImageMobile?.url ?? defaultBackgroundOverlay
    : fullWidthBackgroundImageDesktop?.url ?? defaultBackgroundOverlay

  const isVideo = src.includes('mp4')
  const promptAppHref = formatPromptsPageUrl({
    useCase: UseCase.text,
    language: PromptSupportingLanguageMap.English,
    prompt: inputText,
  })

  useEffect(() => {
    if (enableAiTrail) {
      router.prefetch(promptAppHref)
    }
  }, [])

  const renderTagText = `Here are the tags that is associate to your questions ${tagsOutputText}`
  const isStillFetching =
    visitorGeneratePromptLazyQueryLoading || visitorGeneratePromptWithTagsQueryLoading

  return (
    <MotionContainer>
      <RootStyle>
        {isVideo ? (
          <BackgroundVideo autoPlay loop muted>
            <BackgroundSource src={src} type="video/mp4" />
            <BackgroundSource src={src} type="video/ogg" />
          </BackgroundVideo>
        ) : (
          <HeroOverlayStyle alt="overlay" src={src} variants={varFade().in} />
        )}

        {backgroundImage?.url && (
          <HeroImgStyle alt="hero" src={backgroundImage.url} variants={varFade().inUp} />
        )}

        <Container>
          <ContentStyle>
            <m.div variants={varFade().inRight}>
              <Typography variant="h2" sx={{ color: 'common.white' }}>
                {!!companyName && (
                  <Typography component="span" variant="h2" sx={{ color: 'primary.main' }}>
                    {companyName}
                  </Typography>
                )}
                {!!featureText && (
                  <Typography component="div" variant="h2" sx={{ mt: 0.5 }}>
                    {featureText}
                  </Typography>
                )}
              </Typography>
            </m.div>

            {!!descriptionText && (
              <m.div variants={varFade().inRight}>
                <Typography sx={{ color: 'common.white' }}>
                  <Markdown children={descriptionText || ''} />
                </Typography>
              </m.div>
            )}
            {!!callToActionText && !!callToActionHref && (
              <m.div variants={varFade().inRight}>
                {callToActionHref.startsWith('#') ? (
                  <ScrollLink to={callToActionHref.replace('#', '')} spy smooth offset={-80}>
                    <Button
                      size="large"
                      variant="contained"
                      startIcon={<Iconify icon={'eva:flash-fill'} width={20} height={20} />}
                    >
                      {callToActionText}
                    </Button>
                  </ScrollLink>
                ) : (
                  <Button
                    size="large"
                    target={callToActionHref.includes('http') ? '_blank' : undefined}
                    rel={callToActionHref.includes('http') ? 'noopener' : undefined}
                    variant="contained"
                    href={callToActionHref}
                    startIcon={<Iconify icon={'eva:flash-fill'} width={20} height={20} />}
                  >
                    {callToActionText}
                  </Button>
                )}
              </m.div>
            )}

            {enableAiTrail && (
              <>
                <InputContainer>
                  <m.div variants={varFade().inRight}>
                    {$enum(UseCase)
                      .getValues()
                      .map((useCase) => (
                        <Button
                          key={useCase}
                          disabled={useCase !== UseCase.text}
                          sx={{ mr: 1, mb: isMobile ? 1 : 0 }}
                          size="large"
                          variant="contained"
                          startIcon={<Iconify icon={'eva:flash-fill'} width={20} height={20} />}
                        >
                          {useCase}
                        </Button>
                      ))}
                  </m.div>
                  <TextField
                    onChange={(e) => setInputText(e.target.value)}
                    value={inputText}
                    fullWidth
                    variant="outlined"
                  />
                  <NextLink href={promptAppHref}>
                    <Button href={promptAppHref} size="large" variant="contained">
                      Try It!
                    </Button>
                  </NextLink>
                  {defaultInput && <Typewriter input={defaultInput} />}
                </InputContainer>
              </>
            )}
            {enableAiRecommendation && (
              <>
                <RecommendationInputContainer>
                  <TextField
                    disabled={
                      visitorGeneratePromptWithTagsQueryLoading ||
                      visitorGeneratePromptLazyQueryLoading
                    }
                    onChange={(e) => setTagsInputText(e.target.value)}
                    value={tagsInputText}
                    fullWidth
                    variant="outlined"
                    helperText={
                      isStillFetching && (
                        <Typewriter
                          input={`Your question is: ${tagsInputText}. While waiting for the perfect response. I would like to introduce you some facts about me. Whether you're looking for a quick answer to a question or need some guidance with a project, I'm always here to lend a helping hand. And the best part? You don't have to worry about any awkward small talk or judgement, because I'm here to listen and provide you with the best possible response. So go ahead, ask me anything! I'm always ready and willing to chat. And if you ever have any feedback or suggestions for how I can improve, I'm all ears. After all, my main goal is to make your life easier and more enjoyable.Thank you for choosing us as your trusted AI assistant. Let's chat!`}
                        />
                      )
                    }
                  />
                  <LoadingButton
                    size="large"
                    variant="contained"
                    sx={{ minWidth: '10rem' }}
                    loading={
                      visitorGeneratePromptWithTagsQueryLoading ||
                      visitorGeneratePromptLazyQueryLoading
                    }
                    onClick={() => {
                      setTagsOutputText('')
                      setInputTextDesc('')
                      setDefaultInput('')
                      visitorGeneratePromptLazyQueryLazy({
                        variables: {
                          promptId: 'marketing_questions',
                          language: systemLocaleTransformer(router.locale),
                          prompt: tagsInputText,
                        },
                        context: {
                          headers: {
                            [SHARED_PROMPT_HEADER_FOR_HERO_SECTION]: sys.id,
                          },
                        },
                      }).then(() => {
                        visitorGeneratePromptWithTags({
                          context: {
                            headers: {
                              [SHARED_PROMPT_HEADER_FOR_HERO_SECTION]: sys.id,
                            },
                          },
                          variables: {
                            promptId: 'recommendation_tags_chat',
                            language: systemLocaleTransformer(router.locale),
                            prompt: tagsInputText,
                            tags: tags.map((item) => item?.name).filter(notEmpty) ?? [],
                          },
                        })
                      })
                    }}
                  >
                    Try It!
                  </LoadingButton>
                </RecommendationInputContainer>
                {defaultInput && <Typewriter input={defaultInput} />}
                {inputTextDesc && <Typewriter input={inputTextDesc} />}
                {tagsOutputText && (
                  <Typewriter
                    input={renderTagText}
                    renderText={(text) => {
                      const isFinished = renderTagText?.length === text.length
                      let tags = (
                        text.match(
                          new RegExp(
                            allProductSearchTags.map((searchTag) => searchTag.name).join('|'),
                            'g'
                          )
                        ) || []
                      )
                        .filter((tag) =>
                          allProductSearchTags.find((searchTag) => searchTag.name === tag)
                        )
                        .map(
                          (tag) =>
                            allProductSearchTags.find((searchTag) => searchTag.name === tag) as {
                              id: string
                              name: string
                            }
                        )
                      if (isFinished && tags.length === 0) {
                        tags = sampleSize(allProductSearchTags, 3)
                      }

                      const tagsQueryString = tags.map((tag) => `${tag.id}_${tag.name}`).join('|')

                      return (
                        <>
                          <div>
                            {text}
                            <>
                              {isFinished && (
                                <NextLink
                                  href={`${PATH_PAGE.products}?tags=${tagsQueryString}`}
                                  prefetch
                                >
                                  <Link
                                    sx={{ m: 0.5, textDecoration: 'underline' }}
                                    noWrap
                                    href={`${PATH_PAGE.products}?tags=${tagsQueryString}`}
                                  >
                                    visit the smart recommendation page
                                  </Link>
                                </NextLink>
                              )}
                            </>
                          </div>
                          {tags.map((tag, tagIndex) => (
                            <>
                              {tag ? (
                                <NextLink
                                  key={tag.id + tagIndex}
                                  href={`${PATH_PAGE.products}?tags=${tag.id}_${tag.name}`}
                                >
                                  <Chip key={tag.id + tagIndex} label={tag.name} sx={{ m: 0.5 }} />
                                </NextLink>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      )
                    }}
                  />
                )}
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  )
}
