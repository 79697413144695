import React from 'react'
import { m } from 'framer-motion'
import { Card, Grid, Container, Typography, Link, useTheme } from '@mui/material'
import Image from '@/components/Image'
import { MotionViewport, varFade } from '@/components/animate'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SectionsDetailPersonListFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'

type Person = {
  name: string | null
  nameHref?: string | null
  description: string | null
  descriptionHref?: string | null
  avatar: string | null
  detail: any
}

interface LandingDetailPersonProps {
  contentfulDataFragment: SectionsDetailPersonListFragmentFragment
  backgroundColor?: string
}

export default function LandingDetailPerson(props: LandingDetailPersonProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()
  const personList =
    contentfulDataFragment.componentsCollection?.items.map((item) => ({
      name: item?.title ?? null,
      avatar: item?.avatar?.url ?? null,
      nameHref: item?.titleHref ?? null,
      description: item?.description ?? null,
      descriptionHref: item?.descriptionHref ?? null,
      detail: item?.detail?.json ?? null,
    })) ?? []
  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport} sx={{ textAlign: 'center' }}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        <Grid container spacing={5} justifyContent="center">
          {personList?.map((person, personIndex) => (
            <Grid key={(person.name ?? '') + personIndex} item xs={12} md={4}>
              <m.div variants={varFade().in}>
                <PersonCard person={person} />
              </m.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}

type PersonCardProps = {
  person: Person
}

function PersonCard({ person }: PersonCardProps) {
  const theme = useTheme()
  const { name, nameHref, avatar, description, descriptionHref, detail } = person

  return (
    <Card key={name} sx={{ p: 2.5 }}>
      {nameHref?.trim() ? (
        <Link href={nameHref.trim()} target="_blank" rel="noopener">
          <Typography textAlign="center" variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
            {name}
          </Typography>
        </Link>
      ) : (
        <Typography textAlign="center" variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
          {name}
        </Typography>
      )}
      <Typography textAlign="center" sx={{ mb: 1 }}>
        {descriptionHref?.trim() ? (
          <Link href={descriptionHref.trim()} target="_blank" rel="noopener">
            <Typography variant="caption" sx={{ textDecoration: 'underline' }}>
              {description}
            </Typography>
          </Link>
        ) : (
          <Typography variant="caption">{description}</Typography>
        )}
      </Typography>

      <Image
        style={{
          borderRadius: '5px',
          border: `0.5px solid ${theme.palette.grey[400]}`,
          objectPosition: 'top',
        }}
        alt={name ?? undefined}
        src={avatar ?? undefined}
        ratio="1/1"
      />
      <Typography textAlign="initial" sx={{ mt: 1 }}>
        {documentToReactComponents(detail, {
          renderText: (text) =>
            text.split('\n').reduce<any[]>(
              (children, textSegment, index) => [
                ...children,
                index > 0 && (
                  <br
                    key={index}
                    style={{
                      display: 'block',
                      margin: '15px 0',
                      content: "''",
                    }}
                  />
                ),
                textSegment,
              ],
              []
            ),
        })}
      </Typography>
    </Card>
  )
}
