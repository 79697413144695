import {
  LandingVideo,
  LandingService,
  LandingImageList,
  LandingHumanList,
  LandingDetailPersonList,
  LandingFaqList,
  LandingExperienceTimeline,
  LandingContact,
  LandingFeaturesList,
  LandingMintList,
  LandingTimer,
  LandingGallery,
  LandingForm,
  LandingImage,
} from '@/sections/landing'

import { GlobalSetting, Section } from './page'
import { ContentfulSectionName } from './pageEnum'

export const renderComponent = (
  section: Section,
  sectionBackgroundColors: string[],
  index: number,
  globalSetting?: GlobalSetting
) => {
  switch (section.key) {
    case ContentfulSectionName.SectionsFeaturesList:
      return (
        <LandingFeaturesList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
          globalSetting={globalSetting}
        />
      )
    case ContentfulSectionName.SectionsMinimalsVideo:
      return (
        <LandingVideo
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsServices:
      return (
        <LandingService
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsImagesList:
      return (
        <LandingImageList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsHumanList:
      return (
        <LandingHumanList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsDetailPersonList:
      return (
        <LandingDetailPersonList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsFaqList:
      return (
        <LandingFaqList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsHistoryList:
      return (
        <LandingExperienceTimeline
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )

    case ContentfulSectionName.SectionsMintList:
      return (
        <LandingMintList
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsGallery:
      return (
        <LandingGallery
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsMinimalsContactList:
      return <LandingContact key={section.key + index} contentfulDataFragment={section} />
    case ContentfulSectionName.SectionsTimer:
      return (
        <LandingTimer
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsForm:
      return (
        <LandingForm
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    case ContentfulSectionName.SectionsImage:
      return (
        <LandingImage
          key={section.key + index}
          contentfulDataFragment={section}
          backgroundColor={sectionBackgroundColors[index % sectionBackgroundColors.length]}
        />
      )
    default:
      return null
  }
}
