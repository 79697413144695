import { m } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import { MotionViewport, varFade } from '../../components/animate'
import { Link as ScrollLink } from 'react-scroll'
import { Grid, Card, Stack, Button, Container, Typography } from '@mui/material'
import Iconify from '../../components/Iconify'
import { notEmpty } from '@/utils/common'
import { SectionsMinimalsServicesFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import palette from '@/theme/palette'

interface ServiceCardProps {
  service: Service
}

interface Service {
  title: string | null
  services: string[]
  callToActionText: string | null
  callToActionHref: string | null
}

interface LandingVideoProps {
  contentfulDataFragment: SectionsMinimalsServicesFragmentFragment
  backgroundColor: string
}

export default function LandingService(props: LandingVideoProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()

  const services =
    contentfulDataFragment.componentsCollection?.items.map((item) => ({
      title: item?.title ?? null,
      services: item?.services?.filter(notEmpty) ?? [],
      callToActionText: item?.callToActionText ?? null,
      callToActionHref: item?.callToActionHref ?? null,
    })) ?? []

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
          subtitleFontSize="0.9rem"
        />
        <Grid container spacing={5}>
          {services?.map((service) => (
            <Grid key={service.title} item xs={12} md={4}>
              <m.div
                variants={service.title === 'Standard Plus' ? varFade().inDown : varFade().inUp}
              >
                <ServiceCard service={service} />
              </m.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}

function ServiceCard({ service }: ServiceCardProps) {
  const { title, services, callToActionHref, callToActionText } = service
  return (
    <Card
      sx={{
        p: 5,
        boxShadow: 0,
      }}
      style={{ border: `1px solid ${palette.light.grey[600]}` }}
    >
      <Stack spacing={5}>
        <div>
          <Typography variant="h4">{title}</Typography>
        </div>
        <Stack spacing={2.5}>
          {services.map((item) => (
            <Stack key={item} spacing={1.5} direction="row" alignItems="center">
              <Iconify
                icon={'eva:checkmark-fill'}
                sx={{ color: 'primary.main', width: 30, height: 30, flexShrink: 0 }}
              />
              <Typography variant="body1">{item}</Typography>
            </Stack>
          ))}
        </Stack>
        <Stack spacing={1.5} direction="row" alignItems="center">
          {!!callToActionText && !!callToActionHref ? (
            callToActionHref.startsWith('#') ? (
              <ScrollLink to={callToActionHref} spy smooth offset={-80} style={{ width: '100%' }}>
                <Button size="large" fullWidth variant="outlined">
                  {callToActionText}
                </Button>
              </ScrollLink>
            ) : (
              <Button
                size="large"
                fullWidth
                target={callToActionHref.includes('http') ? '_blank' : undefined}
                rel={callToActionHref.includes('http') ? 'noopener' : undefined}
                variant="outlined"
                disabled={!Boolean(callToActionHref.trim())}
                href={callToActionHref}
              >
                {callToActionText}
              </Button>
            )
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
