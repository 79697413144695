import { AppServiceEnum, SupportLocaleEnum } from '@/appConfig'
import { StaticPrompt } from './constantPrompts'
import {
  PromptInterface,
  PromptSupportingLanguageMap,
  ResponseTone,
  WritingStyle,
} from '@/generated/graphql.prompts.node'
import queryString from 'query-string'
import { Buffer } from 'buffer'
import { makeVar } from '@apollo/client'
import find from 'lodash/find'

export enum PlanType {
  free = 'free',
  basic = 'basic',
  premium = 'premium',
}

// Function to encode ID
export function encodeID(id: string): string {
  const buffer = Buffer.from(id, 'utf-8')
  return buffer.toString('base64')
}

// Function to decode ID
export function decodeID(encodedId: string): string {
  const buffer = Buffer.from(encodedId, 'base64')
  return buffer.toString('utf-8')
}

export const plans: {
  type: PlanType
  concurrent: number
}[] = [
  {
    type: PlanType.free,
    concurrent: 3,
  },
  {
    type: PlanType.basic,
    concurrent: 5,
  },
  {
    type: PlanType.premium,
    concurrent: 7,
  },
]

export const getConcurrentCountByPlanType = (planType: PlanType): number =>
  find(plans, { type: planType })?.concurrent ?? 1

interface shouldDisableMagicButtonParams {
  planType: PlanType
  currentConcurrentCount: number
}
export const shouldDisableMagicButton = (params: shouldDisableMagicButtonParams) => {
  const { planType, currentConcurrentCount } = params
  return (find(plans, { type: planType })?.concurrent ?? 1) <= currentConcurrentCount
}

export const currentConcurrentCountVar = makeVar(0)

export interface urlQueryParams {
  prompt: string
  language: PromptSupportingLanguageMap
  useCase: UseCase
}

export interface urlQueryParamsForEntrepreneurPage {
  language: PromptSupportingLanguageMap
}
export interface urlQueryParamsForChromeExtensionDetailPage {
  language: PromptSupportingLanguageMap
  prompt: string
  tone?: ResponseTone | string
  writingStyle?: WritingStyle | string
}

export const languageTransformer = (language: PromptSupportingLanguageMap) => ({
  label: language === PromptSupportingLanguageMap.MandarinChinese ? 'Mandarin Chinese' : language,
  id: language,
  value: language,
})

export const formatPromptsPageUrl = (params: urlQueryParams): string =>
  `/app/${AppServiceEnum.prompts}?${queryString.stringify({
    ...params,
  })}`

export enum UseCase {
  text = 'text',
  image = 'image',
  video = 'video',
}

export const constantPromptTransform = (prompt: StaticPrompt): PromptInterface => ({
  id: prompt.ID,
  prompt: prompt.Prompt,
  promptHint: prompt.PromptHint,
  promptTeaser: prompt.Teaser,
  createdAt: prompt.CreationTime,
  updatedAt: prompt.RevisionTime,
  statistics: {
    usages: prompt.Usages,
    views: prompt.Views,
    votes: prompt.Votes,
  },
})

interface getPromptFromStaticPromptParams {
  staticPrompt: StaticPrompt
  userPrompt: string
  language: PromptSupportingLanguageMap
  tags?: string[]
}

export const getPromptFromStaticPrompt = (params: getPromptFromStaticPromptParams): string => {
  const { staticPrompt, userPrompt, language, tags = [] } = params
  const result = staticPrompt.Prompt.replace(/\[Please ignore all previous instructions\]/g, '')
    .replace(/\[TARGETLANGUAGE\]/g, language)
    .replace(/\[PROMPT\]/g, userPrompt)
    .replace(/\[TAGS\]/g, tags.join(', '))

  return result
}

interface formatPromptParams {
  promptString: string
  language: PromptSupportingLanguageMap
  userPrompt: string
  writeStyle?: WritingStyle | string
  responseTone?: ResponseTone | string
}
export const formatPrompt = (params: formatPromptParams): string => {
  const { promptString, language, userPrompt, writeStyle, responseTone } = params
  const result = promptString
    .replace(/\[TARGETLANGUAGE\]/g, language)
    .replace(/\[PROMPT\]/g, userPrompt)

  const textToAddAtTheEnd = `
  ${writeStyle ? `The writing style should be ${writeStyle} and is written by a human` : ''}
  ${responseTone ? `The response tone should be ${responseTone} and is written by a human` : ''}
  
  `

  return `${result}
  ${textToAddAtTheEnd}
  `.trim()
}

interface getCreateImagePromptParams {
  concept: string
  version?: midjourneyPromptParamsVersionMap
}

export enum midjourneyPromptParamsVersionMap {
  v1 = 'v1',
  v2 = 'v2',
}

export const getCreateImagePrompt = (params: getCreateImagePromptParams): string => {
  const { concept, version } = params

  const latestMagicString = `
write without wordwraps, headlines, connection words. Write back to back separated with commas and spaces:
[1], [2], [3], [4], [5], [6]

replace [1] with a ${concept}
replace [2] with a concise, descriptive summary of [1]. Ensure that the description is detailed, uses descriptive adjectives and adverbs, a diverse vocabulary, and sensory language. Offer context and background information regarding the subject and consider the image's perspective and point of view. Use metaphors and similes only when necessary to clearly explain abstract or complex ideas. Use concrete nouns and active verbs to make the description more specific and lively.
replace [3] with a concise summary of the scene's environment. Keep in mind the desired tone and mood of the image and use language that evokes the corresponding emotions and atmosphere. Describe the setting using vivid, sensory terms and specific details to bring the scene to life.
replace [4] with a concise description of the mood of the scene, using language that conveys the desired emotions and atmosphere.
replace [5] with a concise description of the atmosphere, using descriptive adjectives and adverbs to create the desired atmosphere while considering the overall tone and mood of the image.
replace [6] with a concise description of the lighting effect, including types of lights, displays, styles, techniques, global illumination, and shadows. Describe the quality, direction, color, and intensity of the light and how it impacts the mood and atmosphere of the scene. Use specific adjectives and adverbs to portray the desired lighting effect and consider how it will interact with the subject and environment.

Ensure word count limit of 1,500 words
The prompt should include the end arguments "--c X --s Y --q 2," where X is a whole number between 1 and 25 and Y is a whole number between 100 and 1000. 
If the subject looks better vertically, add "--ar 2:3" before "--c," and if it looks better horizontally, add "--ar 3:2" before "--c." 
Please randomize the end argument format and fix "--q 2." 
Do not use double quotation marks or punctuation marks, and use a randomized end suffix format.
Start the prompt with exact words of "/imagine prompt: "

now generate the prompt in English*
    `

  let magicStr = ''

  switch (version) {
    case midjourneyPromptParamsVersionMap.v1: {
      magicStr = `
write without wordwraps, headlines, connection words. Write back to back separated with commas and spaces:

[1], [2], [3], [4], [5]

replace [1] with a ${concept}
replace [2] with a list of detailed descriptions about [1]
replace [3] with a list of detailed descriptions about the environment of the scene
replace [4] with a list of detailed descriptions about the mood/feelings and atmosphere of the scene
replace [5] with a list of detailed descriptions about the way the prompt should be realized (e.g. Photography (e.g. Macro, Fisheye Style, Portrait) with camera model and according settings, Painting with detailed descriptions about the materials and working material used, rendering with engine settings, a digital Illustration, a woodburn art (and everything else that could be defined as an output type)

short prompt for an AI-based text to image program that converts a prompt about a topic into an image. Combine a wide variety of rare and commonly used words.

always start the prompt with "/imagine prompt: "
always end the prompt with " --v 4"
never write anything that is in [] brackets
don't use any line breaks

when the prompt subject looks better vertically, add "--ar 2:3" before "--v 4".
When the subject looks better horizontally, use "--ar 3:2" instead.
When the subject looks better in a square, use "--ar 1:1" instead.

now generate the prompt in English*
      `
      break
    }
    case midjourneyPromptParamsVersionMap.v2: {
      magicStr = `
"Create an "imagine prompt" with a word count limit of 1,500 words for the AI-based text-to-image program MidJourney using the following parameters: /imagine prompt: [1], [2], [3], [4], [5], [6].

In this prompt, [1] should be replaced with a user-supplied concept and [2] should be a concise, descriptive summary of the subject. Ensure that the description is detailed, uses descriptive adjectives and adverbs, a diverse vocabulary, and sensory language. Offer context and background information regarding the subject and consider the image's perspective and point of view. Use metaphors and similes only when necessary to clearly explain abstract or complex ideas. Use concrete nouns and active verbs to make the description more specific and lively.

[3] should be a concise summary of the scene's environment. Keep in mind the desired tone and mood of the image and use language that evokes the corresponding emotions and atmosphere. Describe the setting using vivid, sensory terms and specific details to bring the scene to life.

[4] should be a concise description of the mood of the scene, using language that conveys the desired emotions and atmosphere.

[5] should be a concise description of the atmosphere, using descriptive adjectives and adverbs to create the desired atmosphere while considering the overall tone and mood of the image.

[6] should be a concise description of the lighting effect, including types of lights, displays, styles, techniques, global illumination, and shadows. Describe the quality, direction, color, and intensity of the light and how it impacts the mood and atmosphere of the scene. Use specific adjectives and adverbs to portray the desired lighting effect and consider how it will interact with the subject and environment.

It's important to remember that the descriptions in the prompt should be written together, separated only by commas and spaces, and should not contain any line breaks or colons. Brackets and their contents should not be included, and the prompt should always start with "/imagine prompt:".

Ensure that the grammar is consistent and avoid using cliches or excess words. Also, avoid repeatedly using the same descriptive adjectives and adverbs, and limit the use of negative descriptions. Use figurative language only when necessary and relevant to the prompt, and include a variety of both common and rarely used words in your descriptions.

The "imagine prompt" must not exceed 1,500 words. The prompt should include the end arguments "--c X --s Y --q 2," where X is a whole number between 1 and 25 and Y is a whole number between 100 and 1000. If the subject looks better vertically, add "--ar 2:3" before "--c," and if it looks better horizontally, add "--ar 3:2" before "--c." Please randomize the end argument format and fix "--q 2." Donot use double quotation marks or punctuation marks, and use a randomized end suffix format.

Wait for a ${concept} to be provided before generating the prompt."
      `
      break
    }
    default: {
      magicStr = latestMagicString
    }
  }

  return magicStr
}

export const systemLocaleTransformer = (locale?: string) => {
  switch (locale) {
    case SupportLocaleEnum.en:
      return PromptSupportingLanguageMap.English
    case SupportLocaleEnum.zhTw:
      return PromptSupportingLanguageMap.MandarinChinese
    case SupportLocaleEnum.ja:
      return PromptSupportingLanguageMap.Japanese
    default:
      return PromptSupportingLanguageMap.English
  }
}
