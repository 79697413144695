import { ReactNode } from 'react'
import { useTheme, styled } from '@mui/material/styles'
import { MotionViewport } from '../../components/animate'
import { Card, Container, Typography } from '@mui/material'
import useCountdown from '@/hooks/useCountdown'
import useLocales from '@/hooks/useLocales'
import { SectionsTimerFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'

interface LandingTimerProps {
  contentfulDataFragment: SectionsTimerFragmentFragment
  backgroundColor: string
}

const CountdownItemStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const StyledTimerTime = styled(Typography)<{
  appearance: TimerAppearanceEnum
}>(({ theme, appearance }) => ({
  textAlign: 'center',
  ...(appearance === TimerAppearanceEnum.digital && {
    fontFamily: 'Orbitron !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem !important',
    },
  }),
}))

const StyledTimerUnit = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:first-letter': {
    textTransform: 'uppercase',
  },
}))

const StyledCountdownTime = styled(Card)<{
  appearance: TimerAppearanceEnum
}>(({ theme, appearance }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  minWidth: '65px',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3.5),
    marginBottom: theme.spacing(2),
    minWidth: '130px',
  },
  ...(appearance === TimerAppearanceEnum.digital && {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'rgba(255, 255, 255, 0.03)',
    color: theme.palette.common.white,
    overflow: 'hidden',
    transition: '0.5s all',
  }),
}))

const StyledCountdown = styled('div')<{
  appearance: TimerAppearanceEnum
}>(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(12),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1.5),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(3),
  },
}))

enum TimerAppearanceEnum {
  default = 'default',
  digital = 'digital',
}

export default function LandingTimer(props: LandingTimerProps) {
  const { translate } = useLocales()
  const { contentfulDataFragment, backgroundColor } = props
  const countdown = useCountdown(new Date(contentfulDataFragment.startTime))
  const theme = useTheme()

  const appearance = Object.values(TimerAppearanceEnum).includes(
    contentfulDataFragment.appearance as TimerAppearanceEnum
  )
    ? (contentfulDataFragment.appearance as TimerAppearanceEnum)
    : TimerAppearanceEnum.default
  const CountdownTime = ({ children }: { children: ReactNode }) => (
    <StyledCountdownTime appearance={appearance}>{children}</StyledCountdownTime>
  )
  const TimerTime = ({ children }: { children: ReactNode }) => (
    <StyledTimerTime variant="h2" appearance={appearance}>
      {children}
    </StyledTimerTime>
  )

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        <StyledCountdown appearance={appearance}>
          <CountdownItemStyle>
            <CountdownTime>
              <TimerTime>{countdown.days}</TimerTime>
            </CountdownTime>
            <StyledTimerUnit>{translate('unit.days')}</StyledTimerUnit>
          </CountdownItemStyle>

          <CountdownItemStyle>
            <CountdownTime>
              <TimerTime>{countdown.hours}</TimerTime>
            </CountdownTime>
            <StyledTimerUnit>{translate('unit.hours')}</StyledTimerUnit>
          </CountdownItemStyle>

          <CountdownItemStyle>
            <CountdownTime>
              <TimerTime>{countdown.minutes}</TimerTime>
            </CountdownTime>
            <StyledTimerUnit>{translate('unit.minutes')}</StyledTimerUnit>
          </CountdownItemStyle>

          <CountdownItemStyle>
            <CountdownTime>
              <TimerTime>{countdown.seconds}</TimerTime>
            </CountdownTime>
            <StyledTimerUnit>{translate('unit.seconds')}</StyledTimerUnit>
          </CountdownItemStyle>
        </StyledCountdown>
      </Container>
    </RootStyle>
  )
}
