import React, { useState, useEffect } from 'react'
import { Typography, TypographyProps } from '@mui/material'

type Props = {
  input: string
  renderText?: (text: string) => React.ReactElement | string
  styleOverPropsForText?: TypographyProps
  styleOverPropsForCursor?: TypographyProps
  hideCursor?: boolean
  speed?: number
}

const Typewriter = ({
  input,
  renderText,
  styleOverPropsForCursor = {},
  styleOverPropsForText = {},
  hideCursor = false,
  speed = 20,
}: Props) => {
  const [text, setText] = useState('')
  const [index, setIndex] = useState(0)
  const [showCursor, setShowCursor] = useState(true)
  const speedForCursor = speed * 25

  useEffect(() => {
    const timerID = setInterval(() => {
      if (index < input.length) {
        setText((prevText) => prevText + input[index])
        setIndex((prevIndex) => prevIndex + 1)
      } else {
        clearInterval(timerID)
      }
    }, speed)
    return () => clearInterval(timerID)
  }, [input, index, speed])

  useEffect(() => {
    const timerID = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor)
    }, speedForCursor)
    return () => clearInterval(timerID)
  }, [speedForCursor])

  return (
    <div>
      <Typography
        variant="body2"
        sx={{ color: 'common.white', whiteSpace: 'pre-line' }}
        {...styleOverPropsForText}
      >
        {renderText?.(text) ?? text}
      </Typography>
      {hideCursor
        ? null
        : text.length !== input.length && (
            <Typography
              {...styleOverPropsForCursor}
              variant="body2"
              style={{ opacity: showCursor ? 1 : 0 }}
            >
              |
            </Typography>
          )}
    </div>
  )
}

export default Typewriter
