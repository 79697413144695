import { varFade } from '@/components/animate'
import { useTheme, styled } from '@mui/material/styles'
import { Box, Theme, Typography } from '@mui/material'
import { m } from 'framer-motion'

interface TopLandingSectionProps {
  title?: string
  titleColor?: string
  subtitle?: string
  subtitleColor?: string
  subtitleFontSize?: string
}

export const TopLandingSection = (props: TopLandingSectionProps) => {
  const { title = '', titleColor, subtitle = '', subtitleColor, subtitleFontSize } = props
  const theme = useTheme()
  if (title === '' && subtitle === '') return null
  return (
    <Box
      sx={{
        textAlign: 'center',
        mb: 5,
      }}
      id={title}
    >
      <m.div variants={varFade().inUp}>
        <Typography variant="h3" sx={{ mb: 2, color: titleColor ?? theme.palette.primary.main }}>
          {title}
        </Typography>
      </m.div>
      <m.div variants={varFade().inDown}>
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            color: subtitleColor ?? theme.palette.grey[600],
            ...(subtitleFontSize ? { fontSize: subtitleFontSize } : {}),
          }}
        >
          {subtitle}
        </Typography>
      </m.div>
    </Box>
  )
}

export const RootStyle = styled('div')(
  ({
    theme,
    backgroundColor,
    backgroundImage,
  }: {
    theme: Theme
    backgroundColor: string
    backgroundImage?: string
  }) => ({
    backgroundColor: backgroundColor || theme.palette.background.neutral,
    backgroundImage: backgroundImage,
    padding: theme.spacing(10, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
  })
)
