import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { Box, Button, Container } from '@mui/material'
import { MotionViewport } from '@/components/animate'
import ReactPlayer from 'react-player'
import NextLink from 'next/link'
import { SectionsMinimalsVideoFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import { renderOptionFunc, RichTextStyleWrapper } from '@/components/contentful/Shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ISectionsMinimalsVideoFields } from '@/generated/contentful.types.node'
import useResponsive from '@/hooks/useResponsive'
import Image from '@/components/Image'

interface SectionStyle {
  hasMinHeight: boolean
}

const Section = styled.div<SectionStyle>`
  width: 100%;
  min-height: ${(props) => (props.hasMinHeight ? '70vh' : 'auto')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 100%;
  }

  & > div {
    border-radius: 16px;
    overflow: hidden;
  }
  & > div > video {
    display: block;
    object-fit: contain;
  }
`

const StyledTextFullWidth = styled.div`
  width: 100%;
`

interface LandingVideoProps {
  contentfulDataFragment: SectionsMinimalsVideoFragmentFragment
  backgroundColor: string
}

enum GridAreaLandingVideo {
  video = 'video',
  content = 'content',
}

const defaultVideoRatio = {
  desktop: {
    width: '100%',
    height: '70vh',
  },
  mobile: {
    width: '100%',
    height: '50vh',
  },
}

export default function LandingVideo(props: LandingVideoProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const isDesktop = useResponsive('up', 'sm')
  const { mode, videoRatio, callToAction } = contentfulDataFragment
  const theme = useTheme()
  const content = contentfulDataFragment.featureDescription?.json
  const videoUrl = contentfulDataFragment.youtubeEmbedLink ?? contentfulDataFragment.video?.url
  const imageUrl = contentfulDataFragment.image?.url

  const displayVideoRatio: ISectionsMinimalsVideoFields['videoRatio'] =
    videoRatio === '1/1'
      ? '1/1'
      : videoRatio === '3/4'
      ? '3/4'
      : videoRatio === '4/3'
      ? '4/3'
      : 'auto'

  const displayMode: ISectionsMinimalsVideoFields['mode'] =
    mode === 'basic' ? 'basic' : mode === 'two sections' ? 'two sections' : 'basic'

  // TODO: handle video ratio logic
  const videoPropsMobile: {
    width: string
    height: string
  } = displayVideoRatio === 'auto' ? defaultVideoRatio.mobile : defaultVideoRatio.mobile
  // TODO: handle video ratio logic
  const videoPropsDesktop: {
    width: string
    height: string
  } = displayVideoRatio === 'auto' ? defaultVideoRatio.desktop : defaultVideoRatio.desktop
  return (
    <RootStyle
      style={{ paddingRight: 0, paddingLeft: 0 }}
      theme={theme}
      backgroundColor={backgroundColor ?? ''}
    >
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        {displayMode === 'basic' && (
          <>
            {content && (
              <StyledTextFullWidth>
                <RichTextStyleWrapper>
                  {documentToReactComponents(
                    content,
                    renderOptionFunc(contentfulDataFragment.featureDescription?.links.assets)
                  )}
                </RichTextStyleWrapper>
              </StyledTextFullWidth>
            )}
            <Section hasMinHeight={Boolean(videoUrl && !content)}>
              {videoUrl && (
                <ReactPlayer
                  {...(isDesktop ? videoPropsDesktop : videoPropsMobile)}
                  url={videoUrl}
                  controls={true}
                />
              )}
              {imageUrl && <Image disabledEffect visibleByDefault alt={imageUrl} src={imageUrl} />}
            </Section>
          </>
        )}
        {displayMode === 'two sections' && (
          <>
            <Box
              display="grid"
              gridTemplateColumns={
                isDesktop && displayMode === 'two sections' ? `repeat(2, 1fr)` : `repeat(1, 1fr)`
              }
              gridTemplateAreas={
                displayMode === 'two sections'
                  ? isDesktop
                    ? `'${GridAreaLandingVideo.video} ${GridAreaLandingVideo.content}'`
                    : `
          '${GridAreaLandingVideo.video}'
          '${GridAreaLandingVideo.content}'
          `
                  : ''
              }
              gap={isDesktop ? 10 : 5}
            >
              {content && (
                <Box style={{ gridArea: GridAreaLandingVideo.content }}>
                  <StyledTextFullWidth>
                    <RichTextStyleWrapper>
                      {documentToReactComponents(
                        content,
                        renderOptionFunc(contentfulDataFragment.featureDescription?.links.assets)
                      )}
                    </RichTextStyleWrapper>
                  </StyledTextFullWidth>
                  {callToAction && (
                    <NextLink href={callToAction?.link ?? ''} target="_blank">
                      <Button
                        target="_blank"
                        href={callToAction?.link ?? ''}
                        variant="contained"
                        fullWidth
                        size="large"
                      >
                        {callToAction?.title ?? ''}
                      </Button>
                    </NextLink>
                  )}
                </Box>
              )}
              <Box style={{ gridArea: GridAreaLandingVideo.video }}>
                <Section hasMinHeight={Boolean(videoUrl && !content)}>
                  {videoUrl && (
                    <ReactPlayer
                      {...(isDesktop ? videoPropsDesktop : videoPropsMobile)}
                      url={videoUrl}
                      controls={true}
                    />
                  )}
                  {imageUrl && (
                    <Image disabledEffect visibleByDefault alt={imageUrl} src={imageUrl} />
                  )}
                </Section>
              </Box>
            </Box>
          </>
        )}
      </Container>
    </RootStyle>
  )
}
