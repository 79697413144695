// @ts-nocheck
import Big from 'big.js'

const RM = {
  ROUND_DOWN: 0,
  ROUND_HALF_UP: 1,
  ROUND_HALF_EVEN: 2,
  ROUND_UP: 3,
}

Big.RM = RM.ROUND_DOWN

const catchWraper =
  (fn) =>
  (...args) => {
    try {
      return fn(...args)
    } catch (e) {
      return undefined
    }
  }

const OperationGenerator =
  (op) =>
  (...args) =>
    args.reduce((sum, val) => (sum ? sum[op](val) : Big(val)), undefined).toFixed()

const ComparisonGenerator = (op) => (arg1, arg2) => Big(arg1)[op](Big(arg2))

export const add = catchWraper(OperationGenerator('plus'))
export const sub = catchWraper(OperationGenerator('minus'))
export const mul = catchWraper(OperationGenerator('times'))
export const div = catchWraper(OperationGenerator('div'))
export const mod = catchWraper(OperationGenerator('mod'))
export const cmp = catchWraper(OperationGenerator('cmp'))

export const eq = catchWraper(ComparisonGenerator('eq'))
export const gte = catchWraper(ComparisonGenerator('gte'))
export const lte = catchWraper(ComparisonGenerator('lte'))
export const gt = catchWraper(ComparisonGenerator('gt'))
export const lt = catchWraper(ComparisonGenerator('lt'))

export const round = catchWraper((num, precision = 10, type = RM.ROUND_DOWN) =>
  Big(num).round(precision, type).toFixed()
)

export const toPrecision = catchWraper((num, precision) => Big(num).toPrecision(precision))

export const toFixed = catchWraper((num, precision) => Big(num).toFixed(precision))

export const average = (numbers: any[] = []) => {
  const sum = numbers.reduce((acc, num) => add(acc, num), '0')

  return div(sum, numbers.length)
}

/*
  0-800	0 SLP per win
  800-999	1 SLP per win
  1000-1099	3 SLP per win
  1100-1299	6 SLP per win
  1300-1499	9 SLP per win
  1500-1799	12 SLP per win
  1800-1999	15 SLP per win
  2000-2199	18 SLP per win
  2200+	21 SLP per win
*/
export const slpEstimation = ({
  mmr,
  winRate,
  axieCount = '3',
}: {
  mmr: string
  winRate: string
  axieCount?: string
}) => {
  let slpWinPerGame = '0'
  let energy = '0'
  if (gte(axieCount, '20')) {
    energy = '60'
  } else if (gte(axieCount, '10')) {
    energy = '40'
  } else if (gte(axieCount, '3')) {
    energy = '20'
  } else {
    energy = '0'
  }

  if (gte(mmr, '2200')) {
    slpWinPerGame = '21'
  } else if (gte(mmr, '2000')) {
    slpWinPerGame = '18'
  } else if (gte(mmr, '1800')) {
    slpWinPerGame = '15'
  } else if (gte(mmr, '1500')) {
    slpWinPerGame = '12'
  } else if (gte(mmr, '1300')) {
    slpWinPerGame = '9'
  } else if (gte(mmr, '1100')) {
    slpWinPerGame = '6'
  } else if (gte(mmr, '1000')) {
    slpWinPerGame = '3'
  } else if (gte(mmr, '800')) {
    slpWinPerGame = '1'
  } else {
    slpWinPerGame = '0'
  }

  const calWinRate = gt(winRate ?? '0', '0') ? winRate : '50'
  const gainedSlpGamesCount = mul(energy, div(calWinRate, '100'))
  const result = mul(gainedSlpGamesCount, slpWinPerGame)
  return result
}
