import { m } from 'framer-motion'
import { styled } from '@mui/material/styles'
import { Box, Container, Typography, Stack, Link } from '@mui/material'
import Image from '../../components/Image'
import Iconify from '../../components/Iconify'
import { MotionViewport, varFade } from '../../components/animate'
import { SectionsMinimalsContactListFragmentFragment } from '@/generated/graphql'

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(15),
  marginTop: theme.spacing(15),
  marginBottom: theme.spacing(15),
  backgroundColor: theme.palette.background.neutral,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center',
  },
}))

// ----------------------------------------------------------------------

const contactIcons = [
  {
    type: 'email',
    icon: 'eva:email-outline',
  },
  {
    type: 'facebook',
    icon: 'eva:facebook-outline',
  },
  {
    type: 'instagram',
    icon: 'ant-design:instagram-outlined',
  },
  {
    type: 'discord',
    icon: 'mingcute:discord-line',
  },
  {
    type: 'twitter',
    icon: 'eva:twitter-outline',
  },
  {
    type: 'line',
    icon: 'cib:line',
  },
]

interface LandingContactProps {
  contentfulDataFragment: SectionsMinimalsContactListFragmentFragment
}

export default function LandingContact(props: LandingContactProps) {
  const { contentfulDataFragment } = props
  const contacts =
    contentfulDataFragment.linksCollection?.items.map((item) => ({
      link: item?.href ?? null,
      text: item?.title ?? null,
    })) ?? []
  return (
    <Container component={MotionViewport}>
      <ContentStyle id="contact">
        <Box
          component={m.div}
          variants={varFade().inUp}
          sx={{
            mb: { xs: 3, md: 0 },
          }}
          id={contentfulDataFragment.title ?? ''}
        >
          <m.div animate={{ y: [-20, 0, -20] }} transition={{ duration: 4, repeat: Infinity }}>
            <Image
              visibleByDefault
              alt="rocket"
              src="/assets/images/rocket.png"
              disabledEffect
              sx={{ maxWidth: 460 }}
            />
          </m.div>
        </Box>

        <Box
          sx={{
            pl: { md: 10 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Box component={m.div} variants={varFade().inDown} sx={{ color: 'common.white', mb: 5 }}>
            <Typography variant="h2">{contentfulDataFragment.title}</Typography>
          </Box>
          <m.div variants={varFade().inDown}>
            {contacts?.map((contact) => (
              <Stack
                key={contact.text}
                spacing={1.5}
                direction="row"
                alignItems="center"
                sx={{ mb: 3, px: 5 }}
              >
                {contactIcons.find((icon) => icon.type === contact.text) && (
                  <Iconify
                    icon={contactIcons.find((icon) => icon.type === contact.text)?.icon || ''}
                    sx={{
                      width: 30,
                      height: 30,
                      color: 'white',
                    }}
                  />
                )}
                <Link
                  href={
                    contact.text === 'email' ? `mailto: ${contact.link}` : contact.link ?? undefined
                  }
                  target="_blank"
                  rel="noopener"
                  variant="body1"
                  sx={{
                    color: 'white',
                  }}
                >
                  {contact.text === 'email' ? contact.link : contact.text}
                </Link>
              </Stack>
            ))}
          </m.div>
        </Box>
      </ContentStyle>
    </Container>
  )
}
