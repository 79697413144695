import { m } from 'framer-motion'
import {
  Box,
  Card,
  Grid,
  Container,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import Image from '../../components/Image'
import { MotionViewport, varFade } from '../../components/animate'
import { SectionsMinimalsHumanListFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'

// ----------------------------------------------------------------------
type Human = {
  name: string | null
  avatar: string | null
  link: {
    title: string | null
    href?: string | null
  }
}

interface LandingPartnerProps {
  contentfulDataFragment: SectionsMinimalsHumanListFragmentFragment
  backgroundColor?: string
}

export default function LandingPartner(props: LandingPartnerProps) {
  const { backgroundColor, contentfulDataFragment } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const humanList =
    contentfulDataFragment.componentsCollection?.items.map((item) => ({
      name: item?.title ?? null,
      avatar: item?.featureImage?.url ?? null,
      link: {
        title: item?.description ?? null,
        href: item?.descriptionHref ?? null,
      },
    })) ?? []
  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport} sx={{ textAlign: 'center' }}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        <Grid container spacing={isMobile ? 2 : 5} justifyContent="center" alignItems="center">
          {humanList.map((human, humanIndex) => (
            <Grid item xs={12} md={3} key={(human.name ?? '') + humanIndex}>
              <Box component={m.div} variants={varFade().in}>
                <HumanCard human={human} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}

type HumanCardProps = {
  human: Human
}

function HumanCard({ human }: HumanCardProps) {
  const { name, avatar, link } = human

  return (
    <Card key={name} sx={{ p: 1 }}>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      {link?.href?.trim() ? (
        <Link href={link.href.trim()} target="_blank" rel="noopener">
          <Typography variant="body2" sx={{ mb: 2, textDecoration: 'underline' }}>
            {link?.title}
          </Typography>
        </Link>
      ) : (
        <Typography variant="body2" sx={{ mb: 2 }}>
          {link?.title}
        </Typography>
      )}
      <Image
        style={{
          borderRadius: '16px',
          objectPosition: 'top',
        }}
        alt={name ?? undefined}
        src={avatar ?? undefined}
        ratio="1/1"
      />
    </Card>
  )
}
