import { alpha, styled as muiStyled, useTheme } from '@mui/material/styles'
import { Grid, Container, Card, Typography } from '@mui/material'
import { MotionViewport } from '../../components/animate'
import { SectionsFeaturesListFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import Iconify from '@/components/Iconify'
import styled from 'styled-components'
import { GlobalSetting } from '@/services/page'

const CardStyle = muiStyled(Card)(({ theme }) => {
  const shadowCard = (opacity: number) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity)

  return {
    opacity: 0.9,
    maxWidth: 380,
    minHeight: 250,
    height: '100%',
    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(5),
    boxShadow: theme.customShadows.z12,
    transition: 'all 0.2s',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    '&.cardLeft': {
      [theme.breakpoints.up('md')]: { marginTop: -40 },
    },
    '&.cardCenter': {
      [theme.breakpoints.up('md')]: {
        marginTop: -80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          height: 'calc(100% - 40px)',
          borderRadius: Number(theme.shape.borderRadius) * 2,
          backgroundColor: theme.palette.background.paper,
          boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
    },
  }
})

const imageSize = 80

const StyledIconify = styled(Iconify)<{ backgroundColor?: string }>`
  width: ${imageSize}px;
  height: ${imageSize}px;
  background: ${(props) => props.backgroundColor ?? 'transparent'};
  border-radius: 100%;
  padding: 10px;
  color: white;
`

const Image = styled.img<{ backgroundColor?: string }>`
  background: ${(props) => props.backgroundColor ?? 'transparent'};
  width: ${imageSize}px;
  height: ${imageSize}px;
  border-radius: 100%;
  padding: 10px;
`

interface LandingFeatureListProps {
  contentfulDataFragment: SectionsFeaturesListFragmentFragment
  backgroundColor?: string
  globalSetting?: GlobalSetting
}

const IconContainer = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function LandingFeatureList(props: LandingFeatureListProps) {
  const { contentfulDataFragment, backgroundColor, globalSetting } = props
  const theme = useTheme()
  const cards = contentfulDataFragment.componentsCollection?.items ?? []
  const iconWrapperColor = alpha(globalSetting?.companyColor ?? theme.palette.primary.main, 0.8)

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container
        component={MotionViewport}
        sx={{ position: 'relative', mb: 10, textAlign: 'center' }}
      >
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />

        <Grid container spacing={5} direction="row" justifyContent="center" alignItems="flex-start">
          {cards.map((card, index) => (
            <Grid item xs={12} sm={4} key={(card?.title ?? '') + index}>
              <CardStyle>
                <IconContainer>
                  {card?.iconifyIconName ? (
                    <StyledIconify icon={card.iconifyIconName} backgroundColor={iconWrapperColor} />
                  ) : card?.icon?.url ? (
                    <Image
                      src={card.icon.url}
                      alt={card?.title ?? ''}
                      backgroundColor={iconWrapperColor}
                    />
                  ) : (
                    <StyledIconify
                      icon={'line-md:github-loop'}
                      backgroundColor={iconWrapperColor}
                    />
                  )}
                </IconContainer>
                <Typography variant="h6" paragraph style={{ marginTop: 8 }}>
                  {card?.title}
                </Typography>
                <Typography variant="body2">{card?.description}</Typography>
              </CardStyle>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  )
}
