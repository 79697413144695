import { useTheme } from '@mui/material/styles'
import { MotionViewport } from '@/components/animate'
import { Grid, Card, Container, CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { FormProvider } from '@/components/hook-form'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import useAuth from '@/hooks/useAuth'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { SectionsFormFragmentFragment, useUserInsertFormMutation } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from '../Shares'
import FormComponent from './FormComponent'
import { notEmpty } from '@/utils/common'

type FormValuesProps = Record<string, string | boolean | number | Date>
interface LandingFormProps {
  contentfulDataFragment: SectionsFormFragmentFragment
  backgroundColor?: string
}

export default function LandingForm(props: LandingFormProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()
  const { user, isLoading: isAuthLoading } = useAuth()
  const router = useRouter()
  const [userInsertFormMutation] = useUserInsertFormMutation()
  const { enqueueSnackbar } = useSnackbar()

  const methods = useForm<FormValuesProps>({
    defaultValues: {},
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const contentfulFormId = contentfulDataFragment.sys.id
      const formDataCollection = Object.entries(data).map(([k, v]) => ({
        id: k,
        type: v instanceof Date ? 'string' : typeof v,
        value: v instanceof Date ? moment(v).format() : String(v),
      }))

      await userInsertFormMutation({
        variables: {
          contentfulFormId,
          formAnswers: {
            data: formDataCollection.map((formData) => ({
              contentfulFormComponentId: formData.id,
              type: formData.type,
              value: formData.value,
            })),
          },
        },
      })
        .then(() =>
          enqueueSnackbar('submit success', {
            variant: 'success',
          })
        )
        .then(() => {
          const linePath = 'https://line.me/R/ti/p/'
          if (contentfulDataFragment.callToActionHref?.includes(linePath)) {
            router.replace(
              `line://ti/p/${contentfulDataFragment.callToActionHref.replace(linePath, '')}`
            )
          } else {
            router.push(contentfulDataFragment.callToActionHref ?? '/')
          }
        })
    } catch (error) {
      enqueueSnackbar('Submit failed, please submit again or contact us', {
        variant: 'error',
      })
    }
  }

  const formComponents = contentfulDataFragment.componentsCollection?.items?.filter(notEmpty) ?? []

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container id="form-section" component={MotionViewport}>
        <Card sx={{ p: 2.5 }}>
          <TopLandingSection
            title={contentfulDataFragment.title ?? ''}
            subtitle={contentfulDataFragment.description ?? ''}
          />

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
              {formComponents?.map((formComponent) => (
                <Grid
                  key={formComponent.sys.id}
                  item
                  width="100%"
                  style={{ padding: formComponent.type === 'hidden' ? 0 : undefined }}
                >
                  {formComponent.type !== 'email' ? (
                    <FormComponent formComponent={formComponent} />
                  ) : isAuthLoading ? (
                    <CircularProgress />
                  ) : (
                    <FormComponent
                      formComponent={{
                        ...formComponent,
                        defaultValue: formComponent.defaultValue ?? user?.email,
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <LoadingButton
              sx={{ mt: 3 }}
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {contentfulDataFragment.callToActionText}
            </LoadingButton>
          </FormProvider>
        </Card>
      </Container>
    </RootStyle>
  )
}
