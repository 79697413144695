import { GraphQLResolveInfo } from 'graphql'
import { PromptsGraphqlContext } from '../pages/api/prompts'
import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import { print } from 'graphql'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type ChromeExtensionPromptResponse = {
  __typename?: 'ChromeExtensionPromptResponse'
  category: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['String']
  prompt: Scalars['String']
  promptHint: Scalars['String']
  promptTeaser: Scalars['String']
  statistics: PromptStatistics
  title: Scalars['String']
  updatedAt: Scalars['String']
}

export type GenerateImageResponse = {
  __typename?: 'GenerateImageResponse'
  formattedPrompt?: Maybe<Scalars['String']>
  imageCreateV1?: Maybe<ImageCreateResponse>
  imageCreateV2?: Maybe<ImageCreateResponse>
  prompt: Scalars['String']
}

export type GenerateImageResponseImageCreateV1Args = {
  format?: InputMaybe<ImageCreateResponseFormat>
}

export type GenerateImageResponseImageCreateV2Args = {
  format?: InputMaybe<ImageCreateResponseFormat>
}

export type ImageCreateResponse = {
  __typename?: 'ImageCreateResponse'
  created?: Maybe<Scalars['String']>
  data?: Maybe<Array<Maybe<ImageCreateResponseData>>>
  id: Scalars['String']
  promptInput?: Maybe<Scalars['String']>
}

export type ImageCreateResponseData = {
  __typename?: 'ImageCreateResponseData'
  b64_json?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum ImageCreateResponseFormat {
  B64Json = 'b64_json',
  Url = 'url',
}

export type Mutation = {
  __typename?: 'Mutation'
  updatePromptForChromeExtensionPrompt: Scalars['Boolean']
}

export type PromptChoice = {
  __typename?: 'PromptChoice'
  finish_reason: Scalars['String']
  index: Scalars['Int']
  text: Scalars['String']
}

export type PromptCreateResponse = {
  __typename?: 'PromptCreateResponse'
  choices: Array<PromptChoice>
  created: Scalars['String']
  id: Scalars['String']
  model: Scalars['String']
  object: Scalars['String']
  usage: PromptUsage
}

export type PromptInterface = {
  __typename?: 'PromptInterface'
  createdAt: Scalars['String']
  id: Scalars['String']
  prompt: Scalars['String']
  promptHint: Scalars['String']
  promptTeaser: Scalars['String']
  statistics: PromptStatistics
  updatedAt: Scalars['String']
}

export type PromptResponse = {
  __typename?: 'PromptResponse'
  createResponse?: Maybe<PromptCreateResponse>
  id: Scalars['String']
  language: PromptSupportingLanguageMap
  prompt: Scalars['String']
  promptInterface?: Maybe<PromptInterface>
}

export type PromptStatistics = {
  __typename?: 'PromptStatistics'
  usages: Scalars['Float']
  views: Scalars['Float']
  votes: Scalars['Float']
}

export enum PromptSupportingLanguageMap {
  Arabic = 'Arabic',
  Bulgarian = 'Bulgarian',
  Catalan = 'Catalan',
  Chinese = 'Chinese',
  Czech = 'Czech',
  Danish = 'Danish',
  Dutch = 'Dutch',
  English = 'English',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Greek = 'Greek',
  Hebrew = 'Hebrew',
  Hindi = 'Hindi',
  Hungarian = 'Hungarian',
  Indonesian = 'Indonesian',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  MandarinChinese = 'MandarinChinese',
  Norwegian = 'Norwegian',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Serbian = 'Serbian',
  Spanish = 'Spanish',
  Swedish = 'Swedish',
  Thai = 'Thai',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Vietnamese = 'Vietnamese',
}

export type PromptUsage = {
  __typename?: 'PromptUsage'
  completion_tokens: Scalars['Float']
  prompt_tokens: Scalars['Float']
  total_tokens: Scalars['Float']
}

export type Query = {
  __typename?: 'Query'
  generateImage?: Maybe<GenerateImageResponse>
  generatePrompt: PromptResponse
  generatePromptForChromeExtensionPrompt: PromptResponse
  generatePromptWithTags: PromptResponse
  prompts: Array<PromptInterface>
  promptsForChromeExtension: Array<ChromeExtensionPromptResponse>
}

export type QueryGenerateImageArgs = {
  empower?: InputMaybe<Scalars['Boolean']>
  prompt: Scalars['String']
}

export type QueryGeneratePromptArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
}

export type QueryGeneratePromptForChromeExtensionPromptArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
  responseTone?: InputMaybe<ResponseTone>
  writeStyle?: InputMaybe<WritingStyle>
}

export type QueryGeneratePromptWithTagsArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
  tags: Array<Scalars['String']>
}

export enum ResponseTone {
  Authoritative = 'Authoritative',
  Clinical = 'Clinical',
  Cold = 'Cold',
  Confident = 'Confident',
  Cynical = 'Cynical',
  Emotional = 'Emotional',
  Empathetic = 'Empathetic',
  Formal = 'Formal',
  Friendly = 'Friendly',
  Humorous = 'Humorous',
  Informal = 'Informal',
  Ironic = 'Ironic',
  Label = 'Label',
  Optimistic = 'Optimistic',
  Pessimistic = 'Pessimistic',
  Playful = 'Playful',
  Sarcastic = 'Sarcastic',
  Serious = 'Serious',
  Sympathetic = 'Sympathetic',
  Tentative = 'Tentative',
  Warm = 'Warm',
}

export enum WritingStyle {
  Academic = 'Academic',
  Analytical = 'Analytical',
  Argumentative = 'Argumentative',
  Conversational = 'Conversational',
  Creative = 'Creative',
  Critical = 'Critical',
  Descriptive = 'Descriptive',
  Epigrammatic = 'Epigrammatic',
  Epistolary = 'Epistolary',
  Expository = 'Expository',
  Informative = 'Informative',
  Instructive = 'Instructive',
  Journalistic = 'Journalistic',
  Label = 'Label',
  Metaphorical = 'Metaphorical',
  Narrative = 'Narrative',
  Persuasive = 'Persuasive',
  Poetic = 'Poetic',
  Satirical = 'Satirical',
  Technical = 'Technical',
}

export type PromptInterfaceFragment = {
  __typename?: 'PromptInterface'
  id: string
  promptHint: string
  promptTeaser: string
}

export type VisitorChromeExtensionPromptResponseFragment = {
  __typename?: 'ChromeExtensionPromptResponse'
  id: string
  title: string
  category: string
  promptHint: string
  promptTeaser: string
  createdAt: string
  updatedAt: string
  statistics: { __typename?: 'PromptStatistics'; usages: number; views: number; votes: number }
}

export type VisitorChromeExtensionPromptResponseWithPromptDetailFragment = {
  __typename?: 'ChromeExtensionPromptResponse'
  prompt: string
  id: string
  title: string
  category: string
  promptHint: string
  promptTeaser: string
  createdAt: string
  updatedAt: string
  statistics: { __typename?: 'PromptStatistics'; usages: number; views: number; votes: number }
}

export type VisitorFetchPromptsQueryVariables = Exact<{ [key: string]: never }>

export type VisitorFetchPromptsQuery = {
  __typename?: 'Query'
  prompts: Array<{
    __typename?: 'PromptInterface'
    id: string
    promptHint: string
    promptTeaser: string
  }>
}

export type VisitorFetchPromptsForChromeExtensionQueryVariables = Exact<{ [key: string]: never }>

export type VisitorFetchPromptsForChromeExtensionQuery = {
  __typename?: 'Query'
  promptsForChromeExtension: Array<{
    __typename?: 'ChromeExtensionPromptResponse'
    id: string
    title: string
    category: string
    promptHint: string
    promptTeaser: string
    createdAt: string
    updatedAt: string
    statistics: { __typename?: 'PromptStatistics'; usages: number; views: number; votes: number }
  }>
}

export type VisitorFetchPromptsForChromeExtensionWithPromptDetailQueryVariables = Exact<{
  [key: string]: never
}>

export type VisitorFetchPromptsForChromeExtensionWithPromptDetailQuery = {
  __typename?: 'Query'
  promptsForChromeExtension: Array<{
    __typename?: 'ChromeExtensionPromptResponse'
    prompt: string
    id: string
    title: string
    category: string
    promptHint: string
    promptTeaser: string
    createdAt: string
    updatedAt: string
    statistics: { __typename?: 'PromptStatistics'; usages: number; views: number; votes: number }
  }>
}

export type VisitorGeneratePromptQueryVariables = Exact<{
  language: PromptSupportingLanguageMap
  prompt: Scalars['String']
  promptId: Scalars['String']
}>

export type VisitorGeneratePromptQuery = {
  __typename?: 'Query'
  generatePrompt: {
    __typename?: 'PromptResponse'
    id: string
    language: PromptSupportingLanguageMap
    prompt: string
    createResponse?: {
      __typename?: 'PromptCreateResponse'
      created: string
      id: string
      model: string
      object: string
      usage: {
        __typename?: 'PromptUsage'
        completion_tokens: number
        prompt_tokens: number
        total_tokens: number
      }
      choices: Array<{
        __typename?: 'PromptChoice'
        finish_reason: string
        index: number
        text: string
      }>
    } | null
  }
}

export const PromptInterfaceFragmentDoc = gql`
  fragment PromptInterface on PromptInterface {
    id
    promptHint
    promptTeaser
  }
`
export const VisitorChromeExtensionPromptResponseFragmentDoc = gql`
  fragment VisitorChromeExtensionPromptResponse on ChromeExtensionPromptResponse {
    id
    title
    category
    promptHint
    promptTeaser
    createdAt
    updatedAt
    statistics {
      usages
      views
      votes
    }
  }
`
export const VisitorChromeExtensionPromptResponseWithPromptDetailFragmentDoc = gql`
  fragment VisitorChromeExtensionPromptResponseWithPromptDetail on ChromeExtensionPromptResponse {
    ...VisitorChromeExtensionPromptResponse
    prompt
  }
  ${VisitorChromeExtensionPromptResponseFragmentDoc}
`
export const VisitorFetchPromptsDocument = gql`
  query visitorFetchPrompts @cached(ttl: 300) {
    prompts {
      ...PromptInterface
    }
  }
  ${PromptInterfaceFragmentDoc}
`
export const VisitorFetchPromptsForChromeExtensionDocument = gql`
  query visitorFetchPromptsForChromeExtension @cached(ttl: 300) {
    promptsForChromeExtension {
      ...VisitorChromeExtensionPromptResponse
    }
  }
  ${VisitorChromeExtensionPromptResponseFragmentDoc}
`
export const VisitorFetchPromptsForChromeExtensionWithPromptDetailDocument = gql`
  query visitorFetchPromptsForChromeExtensionWithPromptDetail @cached(ttl: 300) {
    promptsForChromeExtension {
      ...VisitorChromeExtensionPromptResponseWithPromptDetail
    }
  }
  ${VisitorChromeExtensionPromptResponseWithPromptDetailFragmentDoc}
`
export const VisitorGeneratePromptDocument = gql`
  query visitorGeneratePrompt(
    $language: PromptSupportingLanguageMap!
    $prompt: String!
    $promptId: String!
  ) {
    generatePrompt(prompt: $prompt, promptId: $promptId, language: $language) {
      id
      language
      prompt
      createResponse {
        created
        id
        model
        object
        usage {
          completion_tokens
          prompt_tokens
          total_tokens
        }
        choices {
          finish_reason
          index
          text
        }
      }
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action()
const VisitorFetchPromptsDocumentString = print(VisitorFetchPromptsDocument)
const VisitorFetchPromptsForChromeExtensionDocumentString = print(
  VisitorFetchPromptsForChromeExtensionDocument
)
const VisitorFetchPromptsForChromeExtensionWithPromptDetailDocumentString = print(
  VisitorFetchPromptsForChromeExtensionWithPromptDetailDocument
)
const VisitorGeneratePromptDocumentString = print(VisitorGeneratePromptDocument)
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    visitorFetchPrompts(
      variables?: VisitorFetchPromptsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data: VisitorFetchPromptsQuery
      extensions?: any
      headers: Dom.Headers
      status: number
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<VisitorFetchPromptsQuery>(
            VisitorFetchPromptsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'visitorFetchPrompts',
        'query'
      )
    },
    visitorFetchPromptsForChromeExtension(
      variables?: VisitorFetchPromptsForChromeExtensionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data: VisitorFetchPromptsForChromeExtensionQuery
      extensions?: any
      headers: Dom.Headers
      status: number
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<VisitorFetchPromptsForChromeExtensionQuery>(
            VisitorFetchPromptsForChromeExtensionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'visitorFetchPromptsForChromeExtension',
        'query'
      )
    },
    visitorFetchPromptsForChromeExtensionWithPromptDetail(
      variables?: VisitorFetchPromptsForChromeExtensionWithPromptDetailQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data: VisitorFetchPromptsForChromeExtensionWithPromptDetailQuery
      extensions?: any
      headers: Dom.Headers
      status: number
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<VisitorFetchPromptsForChromeExtensionWithPromptDetailQuery>(
            VisitorFetchPromptsForChromeExtensionWithPromptDetailDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'visitorFetchPromptsForChromeExtensionWithPromptDetail',
        'query'
      )
    },
    visitorGeneratePrompt(
      variables: VisitorGeneratePromptQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<{
      data: VisitorGeneratePromptQuery
      extensions?: any
      headers: Dom.Headers
      status: number
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<VisitorGeneratePromptQuery>(
            VisitorGeneratePromptDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'visitorGeneratePrompt',
        'query'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  ChromeExtensionPromptResponse: ResolverTypeWrapper<ChromeExtensionPromptResponse>
  Float: ResolverTypeWrapper<Scalars['Float']>
  GenerateImageResponse: ResolverTypeWrapper<GenerateImageResponse>
  ImageCreateResponse: ResolverTypeWrapper<ImageCreateResponse>
  ImageCreateResponseData: ResolverTypeWrapper<ImageCreateResponseData>
  ImageCreateResponseFormat: ImageCreateResponseFormat
  Int: ResolverTypeWrapper<Scalars['Int']>
  Mutation: ResolverTypeWrapper<{}>
  PromptChoice: ResolverTypeWrapper<PromptChoice>
  PromptCreateResponse: ResolverTypeWrapper<PromptCreateResponse>
  PromptInterface: ResolverTypeWrapper<PromptInterface>
  PromptResponse: ResolverTypeWrapper<PromptResponse>
  PromptStatistics: ResolverTypeWrapper<PromptStatistics>
  PromptSupportingLanguageMap: PromptSupportingLanguageMap
  PromptUsage: ResolverTypeWrapper<PromptUsage>
  Query: ResolverTypeWrapper<{}>
  ResponseTone: ResponseTone
  String: ResolverTypeWrapper<Scalars['String']>
  WritingStyle: WritingStyle
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean']
  ChromeExtensionPromptResponse: ChromeExtensionPromptResponse
  Float: Scalars['Float']
  GenerateImageResponse: GenerateImageResponse
  ImageCreateResponse: ImageCreateResponse
  ImageCreateResponseData: ImageCreateResponseData
  Int: Scalars['Int']
  Mutation: {}
  PromptChoice: PromptChoice
  PromptCreateResponse: PromptCreateResponse
  PromptInterface: PromptInterface
  PromptResponse: PromptResponse
  PromptStatistics: PromptStatistics
  PromptUsage: PromptUsage
  Query: {}
  String: Scalars['String']
}

export type ChromeExtensionPromptResponseResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['ChromeExtensionPromptResponse'] = ResolversParentTypes['ChromeExtensionPromptResponse']
> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptHint?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptTeaser?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statistics?: Resolver<ResolversTypes['PromptStatistics'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenerateImageResponseResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['GenerateImageResponse'] = ResolversParentTypes['GenerateImageResponse']
> = {
  formattedPrompt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageCreateV1?: Resolver<
    Maybe<ResolversTypes['ImageCreateResponse']>,
    ParentType,
    ContextType,
    Partial<GenerateImageResponseImageCreateV1Args>
  >
  imageCreateV2?: Resolver<
    Maybe<ResolversTypes['ImageCreateResponse']>,
    ParentType,
    ContextType,
    Partial<GenerateImageResponseImageCreateV2Args>
  >
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ImageCreateResponseResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['ImageCreateResponse'] = ResolversParentTypes['ImageCreateResponse']
> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ImageCreateResponseData']>>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptInput?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ImageCreateResponseDataResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['ImageCreateResponseData'] = ResolversParentTypes['ImageCreateResponseData']
> = {
  b64_json?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  updatePromptForChromeExtensionPrompt?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
}

export type PromptChoiceResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptChoice'] = ResolversParentTypes['PromptChoice']
> = {
  finish_reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromptCreateResponseResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptCreateResponse'] = ResolversParentTypes['PromptCreateResponse']
> = {
  choices?: Resolver<Array<ResolversTypes['PromptChoice']>, ParentType, ContextType>
  created?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  usage?: Resolver<ResolversTypes['PromptUsage'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromptInterfaceResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptInterface'] = ResolversParentTypes['PromptInterface']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptHint?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptTeaser?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statistics?: Resolver<ResolversTypes['PromptStatistics'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromptResponseResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptResponse'] = ResolversParentTypes['PromptResponse']
> = {
  createResponse?: Resolver<Maybe<ResolversTypes['PromptCreateResponse']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  language?: Resolver<ResolversTypes['PromptSupportingLanguageMap'], ParentType, ContextType>
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promptInterface?: Resolver<Maybe<ResolversTypes['PromptInterface']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromptStatisticsResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptStatistics'] = ResolversParentTypes['PromptStatistics']
> = {
  usages?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  views?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  votes?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromptUsageResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['PromptUsage'] = ResolversParentTypes['PromptUsage']
> = {
  completion_tokens?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  prompt_tokens?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  total_tokens?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = PromptsGraphqlContext,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  generateImage?: Resolver<
    Maybe<ResolversTypes['GenerateImageResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGenerateImageArgs, 'prompt'>
  >
  generatePrompt?: Resolver<
    ResolversTypes['PromptResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneratePromptArgs, 'prompt' | 'promptId'>
  >
  generatePromptForChromeExtensionPrompt?: Resolver<
    ResolversTypes['PromptResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneratePromptForChromeExtensionPromptArgs, 'prompt' | 'promptId'>
  >
  generatePromptWithTags?: Resolver<
    ResolversTypes['PromptResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneratePromptWithTagsArgs, 'prompt' | 'promptId' | 'tags'>
  >
  prompts?: Resolver<Array<ResolversTypes['PromptInterface']>, ParentType, ContextType>
  promptsForChromeExtension?: Resolver<
    Array<ResolversTypes['ChromeExtensionPromptResponse']>,
    ParentType,
    ContextType
  >
}

export type Resolvers<ContextType = PromptsGraphqlContext> = {
  ChromeExtensionPromptResponse?: ChromeExtensionPromptResponseResolvers<ContextType>
  GenerateImageResponse?: GenerateImageResponseResolvers<ContextType>
  ImageCreateResponse?: ImageCreateResponseResolvers<ContextType>
  ImageCreateResponseData?: ImageCreateResponseDataResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  PromptChoice?: PromptChoiceResolvers<ContextType>
  PromptCreateResponse?: PromptCreateResponseResolvers<ContextType>
  PromptInterface?: PromptInterfaceResolvers<ContextType>
  PromptResponse?: PromptResponseResolvers<ContextType>
  PromptStatistics?: PromptStatisticsResolvers<ContextType>
  PromptUsage?: PromptUsageResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
}
