import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Container } from '@mui/material'
import { SectionsImageFragmentFragment } from '@/generated/graphql'
import { MotionViewport } from '../../components/animate'
import { RootStyle } from './Shares'
import Image from '@/components/Image'
import { Link as ScrollLink } from 'react-scroll'

interface LandingImageProps {
  contentfulDataFragment: SectionsImageFragmentFragment
  backgroundColor: string
}

export default function LandingImage(props: LandingImageProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const imageObject = isMobile
    ? contentfulDataFragment.mobileImage ?? contentfulDataFragment.image
    : contentfulDataFragment.image
  const hasLink = Boolean(contentfulDataFragment.callToActionHref)
  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        {imageObject?.url && (
          <>
            {hasLink ? (
              <a
                target="_blank"
                href={contentfulDataFragment.callToActionHref ?? undefined}
                rel="noopener noreferrer"
              >
                <Image alt={imageObject?.title ?? ''} src={imageObject.url} />
              </a>
            ) : (
              <ScrollLink to="form-section" spy smooth offset={isMobile ? -80 : -110}>
                <Image alt={imageObject?.title ?? ''} src={imageObject.url} />
              </ScrollLink>
            )}
          </>
        )}
      </Container>
    </RootStyle>
  )
}
