export enum ContentfulSectionName {
  SectionsHero = 'SectionsHero',
  SectionsMinimalsVideo = 'SectionsMinimalsVideo',
  SectionsMinimalsServices = 'SectionsMinimalsServices',
  SectionsMinimalsImagesList = 'SectionsMinimalsImagesList',
  SectionsMinimalsHumanList = 'SectionsMinimalsHumanList',
  SectionsDetailPersonList = 'SectionsDetailPersonList',
  SectionsFaqList = 'SectionsFaqList',
  SectionsMinimalsHistoryList = 'SectionsMinimalsHistoryList',
  SectionsMinimalsContactList = 'SectionsMinimalsContactList',
  SectionsFeaturesList = 'SectionsFeaturesList',
  SectionsMintList = 'SectionsMintList',
  SectionsTimer = 'SectionsTimer',
  SectionsMinimalsGallery = 'SectionsMinimalsGallery',
  SectionsForm = 'SectionsForm',
  SectionsImage = 'SectionsImage',
}
