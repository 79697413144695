import { Typography, Box, Container, useTheme } from '@mui/material'
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
} from '@mui/lab'
import { MotionViewport } from '@/components/animate'
import useResponsive from '@/hooks/useResponsive'
import Markdown from '@/components/Markdown'
import styled, { css } from 'styled-components'
import ImageComponent from '@/components/Image'
import moment from 'moment'
import { SectionsMinimalsHistoryListFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'

interface LandingDetailPersonProps {
  contentfulDataFragment: SectionsMinimalsHistoryListFragmentFragment
  backgroundColor?: string
}

export default function LandingExperienceTimeline(props: LandingDetailPersonProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()

  const experiences =
    contentfulDataFragment.componentsCollection?.items.map((item) => ({
      title: item?.title ?? null,
      description: item?.description ?? null,
      time: item?.displayTime ?? null,
      image: item?.featureImage?.url ?? null,
    })) ?? []
  const componentDateFormat = contentfulDataFragment.componentDateFormat
  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
      </Container>
      <Container sx={{ pb: 5, textAlign: 'center' }}>
        <Box sx={{ position: 'relative' }}>
          <ExperienceTimeline
            componentDateFormat={componentDateFormat ?? undefined}
            experiences={experiences || []}
          />
        </Box>
      </Container>
    </RootStyle>
  )
}

function ExperienceTimeline({
  experiences,
  componentDateFormat,
}: {
  experiences: ExperienceItemProps['experience'][]
  componentDateFormat?: string
}) {
  const isDesktop = useResponsive('up', 'md')

  return (
    <Timeline position={isDesktop ? 'alternate' : 'right'}>
      {experiences.map((experience, index) => (
        <OrderItem
          key={experience.title}
          experience={experience}
          isLast={index === experiences.length - 1}
          componentDateFormat={componentDateFormat}
        />
      ))}
    </Timeline>
  )
}

// ----------------------------------------------------------------------
const StyledIframe = styled.iframe`
  width: 500px;
  height: 280px;

  @media (max-width: 1120px) {
    width: 100%;
  }
  @media (max-width: 1120px) and (min-width: 768px) {
    height: 200px;
  }
  @media (max-width: 768px) and (min-width: 576px) {
    height: 170px;
  }
  @media (max-width: 576px) {
    height: 120px;
  }
`
const StyledTimelineItem = styled(TimelineItem)<{ isDesktop?: boolean }>`
  ${(props) =>
    !props.isDesktop &&
    css`
      &:before {
        content: none !important;
      }
    `}
`

type ExperienceItemProps = {
  experience: {
    title: string | null
    description: string | null
    time: string | number | null
    video?: string
    image: string | null
  }
  isLast: boolean
  componentDateFormat?: string
}

function OrderItem({ experience, isLast, componentDateFormat }: ExperienceItemProps) {
  const { title, description, time, image, video } = experience
  const isDesktop = useResponsive('up', 'md')

  const mediaComponent = video ? (
    <StyledIframe
      src={video}
      title={title ?? undefined}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{ marginBottom: 1 }}
    />
  ) : image ? (
    <ImageComponent alt={title ?? undefined} src={image} sx={{ marginBottom: 1 }} />
  ) : (
    <></>
  )

  const renderComponent = () => {
    if (time) {
      return (
        <>
          {time && (
            <Typography sx={{ color: 'text.secondary', marginBottom: 0.5 }}>
              {moment(time).format(componentDateFormat ?? 'YYYY-MM-DD')}
            </Typography>
          )}
          {!isDesktop && mediaComponent}

          <Typography variant="h4" mb={2}>
            {title}
          </Typography>
        </>
      )
    }
    return (
      <>
        <Typography mb={2} variant="body1">
          {title}
        </Typography>
        {!isDesktop && mediaComponent}
      </>
    )
  }

  return (
    <StyledTimelineItem isDesktop={isDesktop}>
      {isDesktop && (
        <TimelineOppositeContent color="text.secondary">{mediaComponent}</TimelineOppositeContent>
      )}

      <TimelineSeparator>
        <TimelineDot color={'primary'} />
        {isLast && isDesktop ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {renderComponent()}
        <Typography component="div" sx={{ color: 'text.secondary' }}>
          <Markdown children={description || ''} />
        </Typography>
      </TimelineContent>
    </StyledTimelineItem>
  )
}
