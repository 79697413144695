import Slider from 'react-slick'
import { styled } from '@mui/material/styles'
import { useState, useRef, useEffect } from 'react'
import { Container, useTheme, Box, alpha, Link } from '@mui/material'
import { MotionViewport } from '@/components/animate'
import {
  Asset,
  SectionsMinimalsImagesListFragmentFragment,
  SectionsImageFragmentFragment,
} from '@/generated/graphql'
import { notEmpty } from '@/utils/common'
import { RootStyle, TopLandingSection } from './Shares'
import useResponsive from '@/hooks/useResponsive'
import { ISectionsMinimalsImagesListFields } from '@/generated/contentful.types.node'
import ImageComponent from '@/components/Image'
import Carousel, { CarouselArrowIndex } from '@/components/carousel'
import Lightbox from '@/components/lightbox'
import cssStyles from '@/utils/cssStyles'
import { useRouter } from 'next/router'
import NextLink from 'next/link'

const THUMB_SIZE = 64

interface CarouselThumbnailProps {
  imageUrls: string[]
}

type StyledThumbnailsContainerProps = {
  length: number
}

const StyledThumbnailsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'length',
})<StyledThumbnailsContainerProps>(({ length, theme }) => ({
  margin: theme.spacing(0, 'auto'),
  position: 'relative',

  '& .slick-slide': {
    opacity: 0.48,
    '&.slick-current': {
      opacity: 1,
    },
    '& > div': {
      padding: theme.spacing(0, 0.75),
    },
  },

  ...(length === 1 && {
    maxWidth: THUMB_SIZE * 1 + 16,
  }),
  ...(length === 2 && {
    maxWidth: THUMB_SIZE * 2 + 32,
  }),
  ...((length === 3 || length === 4) && {
    maxWidth: THUMB_SIZE * 3 + 48,
  }),
  ...(length >= 5 && {
    maxWidth: THUMB_SIZE * 6,
  }),
  ...(length > 2 && {
    '&:before, &:after': {
      ...cssStyles(theme).bgGradient({
        direction: 'to left',
        startColor: `${alpha(theme.palette.background.default, 0)} 0%`,
        endColor: `${theme.palette.background.default} 100%`,
      }),
      top: 0,
      zIndex: 9,
      content: "''",
      height: '100%',
      position: 'absolute',
      width: (THUMB_SIZE * 2) / 3,
    },
    '&:after': {
      right: 0,
      transform: 'scaleX(-1)',
    },
  }),
}))

const CarouselThumbnail = (props: CarouselThumbnailProps) => {
  const theme = useTheme()
  const { imageUrls } = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const [nav1, setNav1] = useState<Slider | undefined>(undefined)
  const [nav2, setNav2] = useState<Slider | undefined>(undefined)
  const carousel2 = useRef<Carousel | null>(null)
  const slider2 = useRef<Slider | null>(null)
  const carousel1 = useRef<Carousel | null>(null)
  const [selectedImage, setSelectedImage] = useState<number>(-1)

  const carouselSettings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  }

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current)
    }
    if (carousel2.current) {
      setNav2(carousel2.current)
    }
  }, [])

  useEffect(() => {
    carousel1.current?.slickGoTo(currentIndex)
  }, [currentIndex])

  const handlePrevious = () => {
    slider2.current?.slickPrev()
  }

  const handleNext = () => {
    slider2.current?.slickNext()
  }

  const handleOpenLightbox = (imageUrl: string) => {
    const imageIndex = imageUrls.findIndex((url) => url === imageUrl)
    setSelectedImage(imageIndex)
  }

  const handleCloseLightbox = () => {
    setSelectedImage(-1)
  }

  const imagesLightbox = imageUrls.map((imageUrl) => ({ src: imageUrl })) ?? []

  useEffect(() => {
    if (carousel1.current) {
      setNav1(carousel1.current)
    }
    if (carousel2.current) {
      setNav2(carousel2.current)
    }
  }, [])

  const carouselSettings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: imageUrls.length > 3 ? 3 : imageUrls.length,
  }

  const renderThumbnails = (
    <StyledThumbnailsContainer length={imageUrls.length}>
      <Carousel {...carouselSettings2} asNavFor={nav1} ref={carousel2}>
        {imageUrls.map((img, index) => (
          <ImageComponent
            key={img}
            disabledEffect
            alt="thumbnail"
            src={img}
            sx={{
              width: THUMB_SIZE,
              height: THUMB_SIZE,
              borderRadius: 1.5,
              cursor: 'pointer',
              ...(currentIndex === index && {
                border: `solid 2px ${theme.palette.primary.main}`,
              }),
            }}
          />
        ))}
      </Carousel>
    </StyledThumbnailsContainer>
  )

  const renderLargeImg = (
    <Box sx={{ mb: 3, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
      <Carousel {...carouselSettings1} asNavFor={nav2} ref={carousel1}>
        {imageUrls.map((img) => (
          <ImageComponent
            key={img}
            alt="product"
            src={img}
            ratio="1/1"
            onClick={() => handleOpenLightbox(img)}
            sx={{ cursor: 'zoom-in' }}
          />
        ))}
      </Carousel>

      <CarouselArrowIndex
        index={currentIndex}
        total={imageUrls.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Box>
  )

  return (
    <>
      <Box
        sx={{
          '& .slick-slide': {
            float: theme.direction === 'rtl' ? 'right' : 'left',
          },
        }}
      >
        {renderLargeImg}

        {renderThumbnails}
      </Box>

      <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
        onGetCurrentIndex={(index) => setCurrentIndex(index)}
      />
    </>
  )
}

interface LandingImageListProps {
  contentfulDataFragment: SectionsMinimalsImagesListFragmentFragment
  backgroundColor?: string
}

export default function LandingImageList(props: LandingImageListProps) {
  const router = useRouter()
  const { contentfulDataFragment, backgroundColor } = props
  const isDesktop = useResponsive('up', 'sm')
  const {
    gridModeColumnsCountMobile,
    gridModeColumnsCountDesktop,
    displayMode,
    gridModeGapDesktop,
    gridModeGapMobile,
    imagesWithHrefDesktopCollection,
    imagesWithHrefMobileCollection,
  } = contentfulDataFragment
  const mode: ISectionsMinimalsImagesListFields['displayMode'] =
    displayMode === 'slider' ? 'slider' : displayMode === 'grid' ? 'grid' : 'grid'
  const theme = useTheme()
  const mobileImages = [
    ...(contentfulDataFragment.imagesMobileCollection?.items ?? []),
    ...(imagesWithHrefMobileCollection?.items ?? []),
  ]
  const desktopImages = [
    ...(contentfulDataFragment.imagesCollection?.items ?? []),
    ...(imagesWithHrefDesktopCollection?.items ?? []),
  ]

  const displayImages = (
    isDesktop ? desktopImages : mobileImages.length !== 0 ? mobileImages ?? [] : desktopImages ?? []
  ).map((image) => {
    // this is a workaround that server side code does not know about the __typename
    const anyImage: any = image
    if (anyImage?.image?.url) {
      return {
        ...image,
        __typename: 'SectionsImage',
      } as SectionsImageFragmentFragment
    }
    if (anyImage?.url) {
      return {
        ...image,
        __typename: 'Asset',
      } as Asset
    }
    return image
  })

  const imageUrls =
    displayImages
      .map((item) => {
        if (item?.__typename === 'SectionsImage') {
          return item.image?.url
        }
        if (item?.__typename === 'Asset') {
          return item.url
        }
        return ''
      })
      .filter(notEmpty) ?? []

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        {mode === 'slider' && <CarouselThumbnail imageUrls={imageUrls} />}
        {mode === 'grid' && (
          <>
            <Box
              display="grid"
              gridTemplateColumns={
                isDesktop
                  ? `repeat(${gridModeColumnsCountDesktop ?? 1}, 1fr)`
                  : `repeat(${gridModeColumnsCountMobile ?? 1}, 1fr)`
              }
              gap={isDesktop ? gridModeGapDesktop ?? 10 : gridModeGapMobile ?? 5}
            >
              {displayImages?.map((image, urlIndex) => {
                let url = '-'
                let href: string | undefined = undefined

                if (image?.__typename === 'Asset') {
                  url = image?.url ?? '-'
                }
                if (image?.__typename === 'SectionsImage') {
                  url = image?.image?.url ?? '-'
                  href = image.callToActionHref ?? undefined
                }

                return (
                  <Box key={url + urlIndex}>
                    {href ? (
                      <NextLink href={href}>
                        <Link href={href} target="_blank" rel="noopener">
                          <ImageComponent alt={url} src={url} />
                        </Link>
                      </NextLink>
                    ) : (
                      <ImageComponent alt={url} src={url} />
                    )}
                  </Box>
                )
              })}
            </Box>
          </>
        )}
      </Container>
    </RootStyle>
  )
}
