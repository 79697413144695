import { useState } from 'react'
import { m } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import { MotionViewport, varFade } from '@/components/animate'
import Iconify from '@/components/Iconify'
import {
  Grid,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SectionsFaqListFragmentFragment } from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import { RichTextStyleWrapper, renderOptionFunc } from '@/components/contentful/Shared'

interface LandingFaqListProps {
  contentfulDataFragment: SectionsFaqListFragmentFragment
  backgroundColor?: string
}
export default function LandingFaqList(props: LandingFaqListProps) {
  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()
  const [controlled, setControlled] = useState<string | false>(false)

  const handleChangeControlled = (panel: string, isExpanded: boolean) => {
    setControlled(isExpanded ? panel : false)
  }

  const faqList =
    contentfulDataFragment.componentsCollection?.items.map((item) => ({
      title: item?.title ?? null,
      content: item?.content?.json,
    })) ?? []

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        <Grid container spacing={5}>
          {faqList?.map((faq, faqIndex) => {
            const key = (faq?.title ?? '') + faqIndex
            return (
              <Grid key={key} item width="100%">
                <m.div variants={varFade().inUp}>
                  <Accordion
                    expanded={controlled === key}
                    onChange={(_event, isExpanded) =>
                      faq.title && handleChangeControlled(key, isExpanded)
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />
                      }
                    >
                      <Typography variant="subtitle1">{faq.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RichTextStyleWrapper>
                        {documentToReactComponents(faq.content, renderOptionFunc())}
                      </RichTextStyleWrapper>
                    </AccordionDetails>
                  </Accordion>
                </m.div>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </RootStyle>
  )
}
