import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import {
  Card,
  Button,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import { SectionsMintListComponentFragmentFragment } from '@/generated/graphql'
import { injected } from '@/components/wallet/connectors'
import { useWeb3React } from '@web3-react/core'
import { useSnackbar } from 'notistack'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import LoadingButton from '@mui/lab/LoadingButton'
import { mul, sub } from '@/utils/calculation'
import { MerkleTreeResponse } from 'src/pages/api/erc721/[address]/merkleTree'
import { $enum } from 'ts-enum-util'
import Label from '@/components/Label'
import { appsConfigs, AppIdEnum, AppConfig } from '@/appConfig'
import xtfAbi from '../../contracts/xtf-abi.json'

const xtfConfig = _.find(appsConfigs, { appId: AppIdEnum.xtf }) as AppConfig
const xtfAddr =
  xtfConfig?.smartContract?.['0xC04acbD5BD1694Fd09d195Ab014875a21793f38D'].contractAddress ??
  '0xC04acbD5BD1694Fd09d195Ab014875a21793f38D'

const { utils } = Web3

const StyledIcon = styled.img`
  width: 16px;
  height: 27px;
  margin-right: 12px;
`
const FormContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
`
const StyledImage = styled.img`
  width: 100%;
  border-radius: 10px;
  border: transparent !important;
`
const StyledTitle = styled(Typography)`
  color: white;
`
const StyledDescription = styled(Typography)`
  color: #8bacda;
`
const StyledPrice = styled.div`
  color: #00fff7;
`
const StyledButtonContainer = styled.div``
const RootStyle = styled(Card)<{ cardVariant?: MintCardVariantEnum }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background: #1b335a !important;
  border: transparent !important;
  padding: 60px;
  grid-gap: 18px;

  @media (max-width: 768px) {
    padding: 30px;
    grid-gap: 15px;
  }

  ${(props) =>
    props.cardVariant === MintCardVariantEnum.shiny &&
    css`
      && {
        border: 1px solid #ffffff22;
        background-color: #282c34 !important;
        background: linear-gradient(
          0deg,
          rgba(40, 44, 52, 1) 0%,
          rgba(17, 0, 32, 0.5) 100%
        ) !important;
        box-shadow: 0 7px 20px 5px #00000088;
        overflow: hidden;
        transition: 0.5s all;
      }
      &::before {
        position: fixed;
        content: '';
        box-shadow: 0 0 30px 100px #ffffff08;
        top: -10%;
        left: -100%;
        transform: rotate(-45deg);
        height: 60rem;
        transition: 0.7s all;
      }
      &:hover {
        border: 1px solid #ffffff44;
        box-shadow: 0 7px 50px 10px #000000aa;
        transform: scale(1.015);
        filter: brightness(1.3);
        ::before {
          filter: brightness(0.5);
          top: -100%;
          left: 200%;
        }
      }
    `}
  ${(props) =>
    props.cardVariant === MintCardVariantEnum.white &&
    css`
      background: #fff !important;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

      ${StyledTitle} {
        position: relative;
        padding-bottom: 20px;
        color: #372f2b;
        &::after {
          display: block;
          position: absolute;
          bottom: 0px;
          content: '';
          width: 40px;
          height: 2px;
          background: #3b86c4;
        }
      }
      ${StyledDescription} {
        color: #5f5f5f;
      }
      ${StyledPrice} {
        color: #3b86c4;
      }
      ${StyledIcon} {
        filter: invert(46%) sepia(88%) saturate(394%) hue-rotate(164deg) brightness(89%)
          contrast(85%);
      }
    `}
`

const isCorrectNetwork = (
  chainId: number | undefined,
  data: SectionsMintListComponentFragmentFragment
) => {
  switch (chainId) {
    case 1:
      return data.smartContractErc721?.network === 'ethereum mainnet'
    case 4:
      return data.smartContractErc721?.network === 'ethereum rinkeby'
    case 5:
      return data.smartContractErc721?.network === 'ethereum goerli'
    default:
      return false
  }
}

interface ClaimComponentProps {
  contentfulDataFragment: SectionsMintListComponentFragmentFragment
}

const ClaimComponent = (props: ClaimComponentProps) => {
  const { contentfulDataFragment } = props
  const { enqueueSnackbar } = useSnackbar()
  const { account, library, chainId } = useWeb3React()
  const [claimableTokenIds, setClaimableTokenIds] = useState<
    | {
        tokenId: string
        claimed: boolean
      }[]
    | []
    | null
  >(null)
  const [selectedTokenId, setSelectedTokenId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const dependencySmartContractAddress =
    contentfulDataFragment.dependencySmartContractErc721?.smartContractAddress
  const dependencySmartContractAbi = contentfulDataFragment.dependencySmartContractErc721?.abi
  const claimContractAddress = contentfulDataFragment.smartContractErc721?.smartContractAddress
  const claimContractAbi = contentfulDataFragment.smartContractErc721?.abi

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        !dependencySmartContractAddress ||
        !dependencySmartContractAbi ||
        !account ||
        !claimContractAddress ||
        !claimContractAbi
      )
        return
      if (library instanceof Web3) {
        const dependencyContract = new library.eth.Contract(
          dependencySmartContractAbi as AbiItem[],
          dependencySmartContractAddress
        )
        const claimContract = new library.eth.Contract(
          claimContractAbi as AbiItem[],
          claimContractAddress
        )
        dependencyContract.methods
          .walletOfOwner(account)
          .call()
          .then((tokenIds: string[] | []) => {
            Promise.all(
              tokenIds.map((tokenId) =>
                claimContract.methods
                  .nftTokenHadClaimed(tokenId)
                  .call()
                  .then((bool: boolean) => ({
                    tokenId,
                    claimed: bool,
                  }))
              )
            ).then((results) => setClaimableTokenIds(results))
          })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [chainId, library])

  return (
    <FormContainer>
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">可領空頭的NFT</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedTokenId}
          onChange={(_e, value) => setSelectedTokenId(value)}
        >
          {claimableTokenIds === null ? null : claimableTokenIds.length === 0 ? (
            <Typography>很抱歉,您沒有可以領空頭的NFT</Typography>
          ) : (
            claimableTokenIds.map((claimableTokenId) => (
              <FormControlLabel
                key={claimableTokenId.tokenId}
                disabled={claimableTokenId.claimed}
                value={claimableTokenId.tokenId}
                control={<Radio />}
                label={`NFT編號 (${claimableTokenId.tokenId}) ${
                  claimableTokenId.claimed ? '(此NFT已領過空頭)' : ''
                }`}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>

      <LoadingButton
        disabled={selectedTokenId === null || isLoading}
        fullWidth
        size="large"
        variant="contained"
        onClick={async () => {
          if (!claimContractAddress || !claimContractAbi) return
          setIsLoading(true)
          const contract = new library.eth.Contract(
            claimContractAbi as AbiItem[],
            claimContractAddress
          )
          if (library instanceof Web3 && contract && account) {
            try {
              await contract.methods.claim(selectedTokenId).send({
                value: '0',
                from: account,
              })
              setSelectedTokenId(null)
              enqueueSnackbar('Claim Successfully', {
                variant: 'success',
              })
            } catch (error) {
              if (error.code === 4001) {
                enqueueSnackbar(error.message, {
                  variant: 'info',
                })
              } else {
                enqueueSnackbar('Something went wrong', {
                  variant: 'warning',
                })
              }
            }
            return setIsLoading(false)
          }
        }}
        loading={isLoading}
      >
        Claim
      </LoadingButton>
    </FormContainer>
  )
}

interface MintWhitelistComponentProps {
  contentfulDataFragment: SectionsMintListComponentFragmentFragment
  setPriceEthFunction: Dispatch<SetStateAction<null | string>>
  priceEth: null | string
}

const MintWhitelistComponent = (props: MintWhitelistComponentProps) => {
  const { setPriceEthFunction, contentfulDataFragment, priceEth } = props
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const { account, library, chainId } = useWeb3React()
  const [nftPerAddressLimit, setNftPerAddressLimit] = useState('0')
  const [currentUserMinted, setCurrentUserMinted] = useState('0')
  const [mintingAmount, setMintAmount] = useState('1')
  const remainingMintableAmount = sub(nftPerAddressLimit, currentUserMinted)
  const [isLoading, setIsLoading] = useState(false)
  const contractAddress = contentfulDataFragment.smartContractErc721?.smartContractAddress
  const abi = contentfulDataFragment.smartContractErc721?.abi

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!contractAddress || !abi) return
      if (library instanceof Web3 && contractAddress) {
        const contract = new library.eth.Contract(abi as AbiItem[], contractAddress)
        contract.methods
          .nftPerAddressLimit()
          .call()
          .then((r: any) => {
            setNftPerAddressLimit(r)
          })

        contract.methods
          .presaleAddressMintedBalance(account)
          .call()
          .then((r: any) => {
            setCurrentUserMinted(r)
          })

        contract.methods
          .cost()
          .call()
          .then((r: any) => {
            setPriceEthFunction(utils.fromWei(r, 'ether'))
          })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [chainId, library])

  return (
    <FormContainer>
      <TextField
        type="text"
        size="small"
        value={mintingAmount}
        fullWidth
        InputProps={{ sx: { color: theme.palette.primary.main } }}
        onChange={(e) => setMintAmount(e.target.value as string)}
        helperText={
          <>
            <Typography variant="caption">
              Remaining Mintable amount: {remainingMintableAmount}
            </Typography>
          </>
        }
      />
      <LoadingButton
        disabled={Number(remainingMintableAmount) <= 0}
        fullWidth
        size="large"
        variant="contained"
        onClick={async () => {
          if (!contractAddress || !abi) return
          setIsLoading(true)
          const contract = new library.eth.Contract(abi as AbiItem[], contractAddress)
          if (library instanceof Web3 && contractAddress && account) {
            const result = await axios(`/api/erc721/${contractAddress}/merkleTree/`)
            const data = result.data as MerkleTreeResponse
            const proofs = _.get(data, ['output', 'proofs'])
            const proof = _.find(proofs, { address: account })
            if (!proof) {
              setIsLoading(false)
              return enqueueSnackbar('You are not in the whitelist', {
                variant: 'info',
              })
            }
            const merkleProof = proof.hexProof
            try {
              await contract.methods.mintWhitelist(mintingAmount, merkleProof).send({
                value: utils.toWei(mul(mintingAmount, priceEth), 'ether'),
                from: account,
              })
              enqueueSnackbar('Minted Successfully', {
                variant: 'success',
              })
            } catch (error) {
              if (error.code === 4001) {
                enqueueSnackbar(error.message, {
                  variant: 'info',
                })
              } else {
                enqueueSnackbar('Something went wrong', {
                  variant: 'warning',
                })
              }
            }
            return setIsLoading(false)
          }
        }}
        loading={isLoading}
      >
        Mint
      </LoadingButton>
    </FormContainer>
  )
}
interface MintComponentProps {
  contentfulDataFragment: SectionsMintListComponentFragmentFragment
  setPriceEthFunction: Dispatch<SetStateAction<null | string>>
  priceEth: null | string
}

const MintComponent = (props: MintComponentProps) => {
  const { setPriceEthFunction, contentfulDataFragment, priceEth } = props
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const { account, library, chainId } = useWeb3React()
  const [mintingAmount, setMintAmount] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const contractAddress = contentfulDataFragment.smartContractErc721?.smartContractAddress
  const abi = contractAddress === xtfAddr ? xtfAbi : contentfulDataFragment.smartContractErc721?.abi

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!contractAddress || !abi) return
      if (library instanceof Web3 && contractAddress) {
        const contract = new library.eth.Contract(abi as AbiItem[], contractAddress)
        contract.methods
          .cost()
          .call()
          .then((r: any) => {
            setPriceEthFunction(utils.fromWei(r, 'ether'))
          })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [chainId, library])

  return (
    <FormContainer>
      <TextField
        type="text"
        size="small"
        value={mintingAmount}
        fullWidth
        InputProps={{ sx: { color: theme.palette.primary.main } }}
        onChange={(e) => setMintAmount(e.target.value as string)}
      />
      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        onClick={async () => {
          if (!contractAddress || !abi) return
          setIsLoading(true)
          const contract = new library.eth.Contract(abi as AbiItem[], contractAddress)
          if (library instanceof Web3 && contractAddress && account) {
            try {
              await contract.methods.mint(mintingAmount).send({
                value: utils.toWei(mul(mintingAmount, priceEth), 'ether'),
                from: account,
              })
              enqueueSnackbar('Minted Successfully', {
                variant: 'success',
              })
            } catch (error) {
              if (error.code === 4001) {
                enqueueSnackbar(error.message, {
                  variant: 'info',
                })
              } else {
                enqueueSnackbar('Something went wrong', {
                  variant: 'warning',
                })
              }
            }
            return setIsLoading(false)
          }
        }}
        loading={isLoading}
      >
        Mint
      </LoadingButton>
    </FormContainer>
  )
}
interface MintCardProps {
  contentfulDataFragment: SectionsMintListComponentFragmentFragment
}

enum MintCardVariantEnum {
  card = 'card',
  section = 'section',
  shiny = 'shiny',
  white = 'white',
}

export default function MintCard(props: MintCardProps) {
  const { contentfulDataFragment } = props
  const { active, account, activate, chainId } = useWeb3React()
  const { enqueueSnackbar } = useSnackbar()
  const [priceEth, setPriceEth] = useState<null | string>(null)
  async function connect() {
    activate(injected, (error) => {
      switch (error.name) {
        case 'NoEthereumProviderError': {
          return enqueueSnackbar('找不到您的狐狸錢包', { variant: 'warning' })
        }
        default:
          return enqueueSnackbar('請確認您的狐狸錢包正確的連結到網頁', { variant: 'warning' })
      }
    })
  }

  const renderCallToActionView = () => {
    switch (contentfulDataFragment.smartContractCallToActionName) {
      case 'mintWhitelist': {
        return (
          <MintWhitelistComponent
            contentfulDataFragment={contentfulDataFragment}
            setPriceEthFunction={setPriceEth}
            priceEth={priceEth}
          />
        )
      }
      case 'claim': {
        return <ClaimComponent contentfulDataFragment={contentfulDataFragment} />
      }
      case 'mint': {
        return (
          <MintComponent
            contentfulDataFragment={contentfulDataFragment}
            setPriceEthFunction={setPriceEth}
            priceEth={priceEth}
          />
        )
      }
      default: {
        return (
          <FormContainer>
            <LoadingButton fullWidth size="large" variant="contained" disabled>
              DEFAULT
            </LoadingButton>
          </FormContainer>
        )
      }
    }
  }

  const variant = $enum(MintCardVariantEnum)
    .getValues()
    .includes(contentfulDataFragment.style as MintCardVariantEnum)
    ? (contentfulDataFragment.style as MintCardVariantEnum)
    : MintCardVariantEnum.card

  return (
    <RootStyle cardVariant={variant}>
      {contentfulDataFragment.smartContractErc721?.network !== 'ethereum mainnet' && (
        <Label
          variant="filled"
          color="warning"
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          {contentfulDataFragment.smartContractErc721?.network}
        </Label>
      )}
      <StyledImage
        src={contentfulDataFragment.smartContractErc721?.displayImage?.url ?? ''}
        alt={contentfulDataFragment.title ?? ''}
      />
      <StyledTitle variant="h4" style={{ textAlign: 'left' }}>
        {contentfulDataFragment.title}
      </StyledTitle>
      <StyledDescription variant="h6">{contentfulDataFragment.description}</StyledDescription>

      <StyledPrice style={{ display: 'flex', alignItems: 'center' }}>
        <StyledIcon src="/icons/icon-ethereum.svg" />
        <Typography variant="h5" style={{ textAlign: 'left' }}>
          {priceEth ?? contentfulDataFragment.price} ETH
        </Typography>
      </StyledPrice>

      <StyledButtonContainer>
        {!account || !active ? (
          <Button fullWidth variant="outlined" onClick={connect}>
            Connect to MetaMask
          </Button>
        ) : isCorrectNetwork(chainId, contentfulDataFragment) === false ? (
          <Button disabled fullWidth variant="outlined">
            Wrong Network
          </Button>
        ) : (
          renderCallToActionView()
        )}
      </StyledButtonContainer>
    </RootStyle>
  )
}
