import moment from 'moment'
import {
  RHFSelect,
  RHFTextField,
  RHFSwitch,
  RHFRadioGroup,
  RHFDatePicker,
  RHFDateTimePicker,
  RHFTimePicker,
  RHFCheckbox,
  RHFSlider,
} from '@/components/hook-form'
import { useTheme } from '@mui/material/styles'
import { SectionsFormComponentFragmentFragment } from '@/generated/graphql'
import { notEmpty } from '@/utils/common'

type FormComponentProps = {
  formComponent: SectionsFormComponentFragmentFragment
}
type FormComponentType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'checkbox'
  | 'radio'
  | 'range'
  | 'password'
  | 'email'
  | 'url'
  | 'hidden'
  | 'tel'
  | 'date'
  | 'datetime-local'
  | 'time'
  | 'month'
  | 'year'
  | 'select'
  | 'switch'

export default function FormComponent({ formComponent }: FormComponentProps) {
  const theme = useTheme()

  const {
    type,
    label,
    name,
    placeholder,
    helperText,
    defaultValue,
    size,
    maxLength,
    minLength,
    max,
    min,
    required,
    disabled,
    readOnly,
    defaultChecked,
    fullWidth,
    pattern,
    multiple,
    options,
    step,
  } = {
    type: formComponent.type as FormComponentType,
    label: formComponent.label ?? '',
    name: formComponent.sys.id,
    placeholder: formComponent.placeholder ?? undefined,
    helperText: formComponent.helperText ?? undefined,
    defaultValue:
      formComponent.type !== 'checkbox' && formComponent.type !== 'switch'
        ? formComponent.defaultValue ?? ''
        : formComponent.defaultChecked ?? false,
    size: (formComponent.size as 'small' | 'medium') ?? undefined,
    maxLength: formComponent.maxLength ?? undefined,
    minLength: formComponent.minLength ?? undefined,
    max: formComponent.max ?? undefined,
    min: formComponent.min ?? undefined,
    required: formComponent.required ?? false,
    disabled: formComponent.disabled ?? false,
    readOnly: formComponent.readOnly ?? false,
    defaultChecked: formComponent.defaultChecked ?? false,
    fullWidth: formComponent.fullWidth ?? false,
    pattern: formComponent.pattern ?? undefined,
    multiple: formComponent.multiple ?? false,
    options: formComponent.options?.filter(notEmpty) ?? [],
    step: formComponent.step ?? undefined,
  }

  const SharedTextField = ({
    type,
    ...others
  }: { type: FormComponentType } & Omit<React.ComponentProps<typeof RHFTextField>, 'name'>) => (
    <RHFTextField
      {...others}
      type={type}
      style={type === 'hidden' ? { display: 'none' } : {}}
      placeholder={placeholder}
      name={name}
      label={label}
      defaultValue={defaultValue}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      helperText={helperText}
      size={size}
      inputProps={{ maxLength, minLength, max, min, readOnly, pattern, step }}
    />
  )

  const SharedDatePicker = (
    datePickerProps: Partial<
      Pick<React.ComponentProps<typeof RHFDatePicker>, 'inputFormat' | 'views' | 'rifmFormat'>
    >
  ) => (
    <RHFDatePicker
      {...datePickerProps}
      name={name}
      helperText={helperText}
      defaultValue={defaultValue}
      minDate={min ? moment(min) : undefined}
      maxDate={max ? moment(max) : undefined}
      label={label}
    />
  )

  switch (type as FormComponentType) {
    case 'text':
    case 'number':
    case 'email':
    case 'password':
    case 'password':
    case 'url':
    case 'tel':
    case 'hidden':
      return <SharedTextField type={type} />
    case 'textarea':
      return <SharedTextField type="textarea" multiline minRows={4} maxRows={10} />
    case 'select':
      return (
        <RHFSelect
          name={name}
          label={label}
          size={size}
          fullWidth={fullWidth}
          helperText={helperText}
          multiple={multiple}
          defaultValue={defaultValue || options?.[0]}
        >
          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </RHFSelect>
      )
    case 'radio':
      return <RHFRadioGroup name={name} label={label} options={options} />
    case 'range':
      return (
        <RHFSlider
          label={label}
          name={name}
          max={Number(max)}
          min={Number(min)}
          defaultValue={Number(defaultValue)}
          step={step}
          size={size}
          disabled={disabled}
          marks={[
            {
              value: Number(min),
              label: min ?? '',
            },
            {
              value: Number(max),
              label: max ?? '',
            },
          ]}
        />
      )
    case 'checkbox':
      return (
        <RHFCheckbox
          defaultChecked={defaultChecked}
          defaultValue={defaultValue}
          label={label}
          name={name}
        />
      )
    case 'switch':
      return (
        <RHFSwitch
          name={name}
          label={label}
          defaultValue={defaultValue}
          defaultChecked={defaultChecked}
        />
      )
    case 'date':
      return <SharedDatePicker inputFormat="dd/MM/yyyy" rifmFormat="MM/dd/yyyy" />
    case 'month':
      return <SharedDatePicker views={['month']} inputFormat="MM" />
    case 'year':
      return <SharedDatePicker views={['year']} inputFormat="yyyy" />
    case 'datetime-local':
      return (
        <RHFDateTimePicker
          name={name}
          label={label}
          helperText={helperText}
          defaultValue={defaultValue}
          inputFormat="dd/MM/yyyy hh:mm a"
          minDateTime={min ? moment(min) : undefined}
          maxDateTime={max ? moment(max) : undefined}
        />
      )
    case 'time':
      return (
        <RHFTimePicker
          name={name}
          label={label}
          helperText={helperText}
          defaultValue={defaultValue}
          inputFormat="HH:mm"
          minTime={min ? moment(min) : undefined}
          maxTime={max ? moment(max) : undefined}
        />
      )

    default:
      return <></>
  }
}
