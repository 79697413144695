import { useTheme } from '@mui/material/styles'
import { MotionViewport } from '@/components/animate'
import {
  Container,
  Card,
  IconButton,
  Typography,
  styled as muiStyled,
  CardContent,
  Box,
  CircularProgress,
} from '@mui/material'
import {
  GalleryAssetFragment,
  SectionsMinimalsGalleryFragmentFragment,
  useFetchSectionImagesByIdForGalleryLazyQuery,
} from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import cssStyles from '@/utils/cssStyles'
import Image from '@/components/Image'
import Iconify from '@/components/Iconify'
import LightboxModal from '@/components/LightboxModal'
import { useRouter } from 'next/router'
import { formatLocale } from '@/utils/contentful'
import { useEffect, useState } from 'react'

const CaptionStyle = muiStyled(CardContent)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
}))

interface LandingGalleryProps {
  contentfulDataFragment: SectionsMinimalsGalleryFragmentFragment
  backgroundColor?: string
}
export default function LandingGallery(props: LandingGalleryProps) {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [images, setImages] = useState<GalleryAssetFragment[] | []>([])
  const [loadingItemId, setLoadingItemId] = useState('')
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const { locale } = useRouter()
  const [
    fetchSectionImagesByIdForGalleryLazyQuery,
    {
      loading: fetchSectionImagesByIdForGalleryLazyQueryLoading,
      data: fetchSectionImagesByIdForGalleryLazyQueryData,
    },
  ] = useFetchSectionImagesByIdForGalleryLazyQuery()

  useEffect(() => {
    const images: GalleryAssetFragment[] | [] = (
      fetchSectionImagesByIdForGalleryLazyQueryData?.sectionsMinimalsImagesList?.imagesCollection
        ?.items ?? []
    ).map((item) => ({
      ...item,
      sys: {
        id: item?.sys?.id ?? '',
      },
      url: item?.url ?? '',
      title: item?.title ?? '',
      description: item?.description ?? '',
    }))
    if (images.length > 0) {
      setImages(images)
    }
  }, [fetchSectionImagesByIdForGalleryLazyQueryData])

  const { contentfulDataFragment, backgroundColor } = props
  const theme = useTheme()
  const items = contentfulDataFragment.displayItemsCollection?.items ?? []
  const displayItems = items.map((item, itemIndex) => ({
    ...item,
    id: item?.sys.id ?? String(itemIndex),
    displayImage: item?.imagesCollection?.items?.[0] ?? {
      sys: {
        id: '0',
      },
      url: '',
    },
  }))
  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />

        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
          }}
        >
          {displayItems.map((item) => (
            <GalleryItem
              isLoading={
                fetchSectionImagesByIdForGalleryLazyQueryLoading && item.id === loadingItemId
              }
              galleryId={item.id}
              key={item.id}
              title={item.title ?? null}
              description={item.description ?? null}
              image={item.displayImage}
              onOpenLightbox={() => {
                setLoadingItemId(item.id)
                fetchSectionImagesByIdForGalleryLazyQuery({
                  variables: {
                    id: item.id,
                    locale: formatLocale(locale),
                  },
                }).then(() => {
                  setLoadingItemId('')
                  setIsLightBoxOpen(true)
                  setCurrentImageIndex(0)
                })
              }}
            />
          ))}
        </Box>

        <LightboxModal
          images={images.map((image) => image.url ?? '')}
          mainSrc={images.map((image) => image.url ?? '')[currentImageIndex]}
          photoIndex={currentImageIndex}
          setPhotoIndex={(photoIndex) => setCurrentImageIndex(photoIndex)}
          isOpen={isLightBoxOpen}
          onCloseRequest={() => setIsLightBoxOpen(false)}
        />
      </Container>
    </RootStyle>
  )
}

type GalleryItemProps = {
  title: string | null
  description: string | null
  galleryId: string
  image: GalleryAssetFragment
  onOpenLightbox: (galleryId: string) => void
  isLoading: boolean
}

function GalleryItem({
  title,
  description,
  image,
  onOpenLightbox,
  galleryId,
  isLoading,
}: GalleryItemProps) {
  const { url = '' } = image
  const displayUrl = url ?? ''
  return (
    <Card sx={{ cursor: 'pointer', position: 'relative' }}>
      <Image
        alt="gallery image"
        ratio="1/1"
        src={displayUrl}
        onClick={() => onOpenLightbox(galleryId)}
      />

      <CaptionStyle>
        <div>
          <Typography variant="subtitle1">{title ?? image.title}</Typography>
          <Typography variant="body2" sx={{ opacity: 0.72 }}>
            {description ?? image.description}
          </Typography>
        </div>
        <IconButton color="inherit" onClick={() => onOpenLightbox(galleryId)}>
          {isLoading ? (
            <CircularProgress color="primary" size={40} />
          ) : (
            <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
          )}
        </IconButton>
      </CaptionStyle>
    </Card>
  )
}
