import { m } from 'framer-motion'
import { Grid, Container, useTheme } from '@mui/material'
import { MotionViewport, varFade } from '../../components/animate'
import {
  SectionsMintListComponentFragmentFragment,
  SectionsMintListFragmentFragment,
} from '@/generated/graphql'
import { RootStyle, TopLandingSection } from './Shares'
import MintCard from '../pricing/MintCard'
interface LandingMintListProps {
  contentfulDataFragment: SectionsMintListFragmentFragment
  backgroundColor?: string
}

export default function LandingMintList(props: LandingMintListProps) {
  const { backgroundColor, contentfulDataFragment } = props
  const theme = useTheme()
  const cards = contentfulDataFragment.mintCardsCollection?.items ?? []

  return (
    <RootStyle theme={theme} backgroundColor={backgroundColor ?? ''}>
      <Container component={MotionViewport}>
        <TopLandingSection
          title={contentfulDataFragment.title ?? ''}
          subtitle={contentfulDataFragment.description ?? ''}
        />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {cards
            .filter((card) => card?.isDisplay !== false)
            .map((card, cardIndex) => {
              const data: SectionsMintListComponentFragmentFragment = {
                ...card,
              }
              const isSectionStyle = data.style === 'section'
              return (
                <Grid
                  item
                  xs={12}
                  md={isSectionStyle ? 12 : 6}
                  key={(card?.smartContractErc721?.smartContractAddress ?? '') + cardIndex}
                >
                  <m.div variants={varFade().in}>
                    <MintCard contentfulDataFragment={data} />
                  </m.div>
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </RootStyle>
  )
}
